import './index.css'
import React, {useEffect} from 'react'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from 'react-router-dom';
import { useAmplitude } from '../../hooks/useAmplitude';
import BottomNotify from '../../components/bottomNotify';

export default function PolicyPage(){
    const { tg } = useTelegram();

    const navigate = useNavigate()
    const trackEvent = useAmplitude()

    const callback = () => {
        navigate('/subscribe')
    }

    useEffect(() => {
        tg.BackButton.onClick(callback)
        tg.BackButton.show()

        return () => {
            tg.BackButton.offClick(callback)
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [tg])

    return(
        <div className="policy" onScroll={(e) => {
            if(e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
                trackEvent('scroll_useragreement', {
                    last_screen: true,
                })
            }
        }}>
            <div className="policy__title">Пользовательское соглашение</div>
            <div className="policy__content">
Регламентирует порядок использования Telegram-бота, размещённого по адресу: t.me/wafleebot/policy <br/>
<br/>
Общие положения. Термины и определения. Предмет соглашения <br/>
Определения: <br/>
1. Telegram-бот — комплекс программно-технических средств и программных кодов, реализуемых с использованием API-методов, разработанных telegam.org и публично размещенных по адресу t.me/wafleebot, доступ к которым можно получить по адресу t.me/wafleebot и именуемый как «Мои задачи».<br/>
2. Иной Telegram-бот — комплекс программно-технических средств и программных кодов, реализуемых с использованием API-методов, разработанных telegam.org и публично размещенных по адресу t.me/wafleebot, не именуемые как «Мои задачи».<br/>
3. Администрация ресурса — собственник электронного ресурса, обеспечивающий функционирование электронного ресурса и доступ к нему, а именно «Мои задачи».<br/>
4. Пользователь — физическое лицо, присоединившееся к настоящему Пользовательскому соглашению и получившее доступ к ресурсам Telegram-бота, считается присоединившимся к настоящему Пользовательскому соглашению.<br/>
5. Премиум-пользователь — Пользователь, оплативший одну из типов подписок, указанных в Условиях пользования сервисом, доступ к которому можно получить по адресу t.me/wafleebot.<br/>
6. Информация — любая текстовая, графическая, звуковая, программно-техническая информация и файлы, предоставляемые Telegram-ботом.<br/>
7. Telegram-пользователь — физическое лицо, зарегестрированное в сервисе telegram.org и принявшее правила, описанные по адресу https://telegram.org/privacy.<br/>
8. ID пользователя — уникальный числовой идентификатор, который присваивается каждому Telegram-пользователю.<br/>
9. Имя пользователя — информация, обязательная к заполнению при регистрации Telegram-пользователя, заполняемая в полях Имя и Фамилия и не обязана быть настоящими данными физического лица, заполняющего данные об имени и фамилии. Имя пользователя не является персональными данными, так не не обязано соответствовать реальным данным физического лица и не позволяет однозначно идентифицировать физическое лицо.<br/>
10. Ник пользователя — уникальный символьный идентификатор Telegram-пользователя, не обязательный при регистрации Telegram-пользователя.<br/>
<br/>
Настоящее Пользовательское соглашение регулирует отношения между Администрацией ресурса и Пользователями, осуществляющими любые действия с использованием Telegram-бота.<br/>
Стороны соглашаются с тем, что Информация, размещаемая в Telegram-боте предоставляется без каких-либо гарантий и обязательств со стороны Администрации, в том числе в случае недостоверности (ненадлежащего вида) такой информации. Администрация не отвечает за возможные перебои в функционировании Telegram-бота отсутствие доступа к нему и иные возможные технические неполадки в работе ресурса.<br/>
Сторонами настоящего Пользовательского соглашения выступают Администрация ресурса и Пользователь.<br/>
Настоящее соглашение размещено по постоянному адресу в сети интернет: t.me/wafleebot<br/>
<br/>
Права и обязанности сторон<br/>
Права и обязанности Администрации ресурса:<br/>
Администрация Telegram-бота обязуется не собирать персональные данные Пользователя, а также иные данные, за исключением данных, указанных ниже.<br/>
Администрация Telegram-бота имеет право собирать следующую информацию о Пользователе, не являющуюся персональными:<br/>
1. Время регистрации Пользователя<br/>
2. Запросы, отправленные к Telegram-боту<br/>
3. ID-пользователя<br/>
4. Имя пользователя<br/>
5. Ник пользователя<br/>
<br/>
Администрация Telegram-бота имеет право аннулировать учетные записи Пользователей в случае нарушения требований настоящего Пользовательского соглашения.<br/>
Администрация ресурса имеет право в любое время внести изменения в настоящее Пользовательское соглашение в любой его части, без предварительного и последующего уведомления об этом Пользователей.<br/>
<br/>
Права и обязанности Пользователя:<br/>
Начав диалог с Telegram-ботом, Пользователь обязуется использовать Telegram-бот в соответствии с условиями настоящего Пользовательского соглашения.<br/>
Пользователь вправе отказаться от настоящего Пользовательского соглашения, остановив Telegram-бот и прекратив им пользоваться.<br/>
Пользователь имеет право использовать лишь ту часть Информации, размещенной в Telegram-боте, доступ к которой открыт и разрешен Администрацией Telegram-бота.<br/>
Пользователь обязуется не подрывать деятельность Telegram-бота, а равно не использовать программно-технические методы и средства воздействия на Telegram-бот, с целью дестабилизировать его работу.<br/>
Пользователи не имеют право использовать информацию, представленную Telegram-бот в коммерческих и иных целях без получения на то согласия Администрации ресурса.<br/>
<br/>
Ответственность сторон. Ограничение ответственности<br/>
Администрация ресурса не несет ответственности за выдачу информации, содержащую ссылки на ресурсы, признанные в некоторых странах незаконными.<br/>
Администрация ресурса не собирает и не обрабатывает персональные данные Пользователя. Telegram-бот хранит только ту информацию, которую Пользователь самостоятельно размещает в публичном доступе.<br/>
<br/>
Дополнительные условия<br/>
Переписка между Администрацией Telegram-бота и Пользователями осуществляется по электронной почте (e-mail) по электронному адресу info@ze.studio, а также путем общения через Иной Telegram-бот, размещённый по адресу t.me/wafleebot и именуемый как «Техподдержка Мои задачи». Срок ответа на сообщение Пользователя — до двух рабочих дней.<br/>
<br/>
Заключительные положения<br/>
Изменения и (или) дополнения в настоящее Пользовательское соглашение вносятся путем издания новой редакции Пользовательского соглашения и вступают в силу сразу после публикации.<br/>
Пользовательское соглашение и (или) дополнения (изменения) в него обязательны для всех Пользователей, начавших переписку с Telegram-ботом.<br/>
<br/>
Контакты<br/>
Юридические и технические вопросы: info@ze.studio<br/>
Почтовый адрес: 127015, Москва г, вн.тер.г. муниципальный округ Бутырский, ул. Большая Новодмитровская, д. 23, помещение 46.<br/>


</div>
<BottomNotify />

        </div>
    )
}