import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import appSlice from './slices/appSlice';

const store = configureStore({
    reducer:{
        userSlice: userSlice,
        appSlice: appSlice,
    }
});
store.subscribe(() => console.log(store.getState()));
export default store