import React, { useEffect, useState } from "react";
import ArrowNoFill from "../../images/Arrow_nofill";
import './index.css'
import Time from "../../images/Time";
import toHumanTime from "../../utils/toHumanTime";
import { useTelegram } from "../../hooks/useTelegram";
import CalendarList from "../calendarList";

export default function CustomCalendar(props) {
    const {
        isDisablePrevious = false,
        setDate: setGlobalDate = () => {},
        closeModal = () => {},
        startedDate = false,
        isSetStartedValue = false,
        disableTime = false,
        isVisibleToday = false,
        startedTime = false,
    } = props;
    const { tg } = useTelegram();
    const days = [
        'пн',
        'вт',
        'ср',
        'чт',
        'пт',
        'сб',
        'вс'
    ];
    const month = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    const todaysDate = new Date();
    const [date, setDate] = useState(todaysDate);
    const [activeContent, setActiveContent] = useState('Date');
    const [selectedDate, setSelectedDate] = useState(
        isSetStartedValue
            ? startedDate
                ? startedDate
                : todaysDate
            : null
        );
    const timeStart = startedTime ? {
        isSet: true,
        minutes: new Date().getMinutes(),
        hours: new Date().getHours(),
    } : {};
    const [time, setTime] = useState(timeStart);

    const checkDate = (day) => {
        return isDisablePrevious && (todaysDate.getFullYear() > date.getFullYear()
        || (todaysDate.getDate() > day && todaysDate.getMonth() >= date.getMonth() && todaysDate.getFullYear() >= date.getFullYear())
        || (todaysDate.getMonth() > date.getMonth() && todaysDate.getFullYear() >= date.getFullYear()))
    }

    const showTodaysDay = (day) => {
        return isVisibleToday && date.getFullYear() === todaysDate.getFullYear() && date.getMonth() === todaysDate.getMonth() && todaysDate.getDate() === day
    }

    useEffect(() => {
        if(tg && !tg.isExpanded) {
            tg.expand();
        }
    }, [tg])

    const firstRow = () => {
        let day = new Date(date.getFullYear(), date.getMonth(), 7).getDay();
        if (day !== 1) {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 7).getDay()
            const prevLastDay = new Date(date.getFullYear(), date.getMonth(), 0).getDate()
            console.log('firstDayOfMonth:', firstDayOfMonth);
            console.log('prevLastDay:', prevLastDay);
            let k = prevLastDay - firstDayOfMonth + 1;
            const mass = []
            for(let j = 0; j < firstDayOfMonth; j++) {
                mass.push(k);
                k++;
            }
            if(mass.length < 7) {
                let day = 1;
                while (mass.length < 7) {
                    mass.push(day);
                    day++;
                }
            }
            return mass.map((day) => (
                <td
                    onClick={() => {
                        if (
                            selectedDate
                            && selectedDate.getFullYear() === date.getFullYear()
                            && selectedDate.getMonth() === date.getMonth()
                            && selectedDate.getDate() === day
                        ) {
                            setSelectedDate(null)
                        } else {
                            setSelectedDate(new Date(date.getFullYear(), date.getMonth(), day))
                        }
                    }}
                    className={`calendar__cell ${(day > 10
                            || checkDate(day)) && "calendar__disabled"}
                        ${
                            showTodaysDay(day) && "calendar__todaysDate"
                        }
                        ${
                            selectedDate?.getDate() === day
                            && selectedDate.getMonth() === date.getMonth()
                            && selectedDate.getFullYear() === date.getFullYear() && 'active'
                        }`
                    }
                >
                    {day}
                </td>
            ))
        } else {
            const mass = []
            let day = 1;
            while (mass.length < 7) {
                mass.push(day);
                day++;
            }
            return mass.map((day) => (
                <td
                    onClick={() => {
                        if (
                            selectedDate
                            && selectedDate.getFullYear() === date.getFullYear()
                            && selectedDate.getMonth() === date.getMonth()
                            && selectedDate.getDate() === day
                        ) {
                            setSelectedDate(null)
                        } else {
                            setSelectedDate(new Date(date.getFullYear(), date.getMonth(), day))
                        }
                    }}
                    className={`calendar__cell
                        ${
                            checkDate(day) && "calendar__disabled"
                        }
                        ${
                            showTodaysDay(day) && "calendar__todaysDate"
                        }
                        ${
                            selectedDate?.getDate() === day
                            && selectedDate.getMonth() === date.getMonth()
                            && selectedDate.getFullYear() === date.getFullYear() && 'active'
                        }`
                    }
                >
                    {day}
                </td>
            ))
        }
    }

    const anotherRows = () => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 7).getDay();
        const daysLeft = 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate() - (7 - firstDayOfMonth);
        const rowsCount = Math.floor(daysLeft / 7);
        const mass = [];
        for(let i = 0; i < rowsCount; i++){
            mass.push(i)
        }
        if((daysLeft / 7) !== rowsCount) {
            mass.push(23);
        }
        return mass.map((row, rowIndex) => {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 7).getDay();
            let newDays = 0;
            return (
                <tr className="calendar__row">
                    {
                        days.map((column, columnIndex) => {
                            let number = 1 + (7 - firstDayOfMonth) + columnIndex + 7 * rowIndex;
                            if (number <= 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate()) {
                                return (
                                    <td
                                        className={`calendar__cell
                                            ${
                                                checkDate(number) && "calendar__disabled"
                                            }
                                            ${
                                                showTodaysDay(number) && "calendar__todaysDate"
                                            }
                                            ${
                                                selectedDate?.getDate() === number
                                                && selectedDate.getMonth() === date.getMonth()
                                                && selectedDate.getFullYear() === date.getFullYear() && 'active'
                                            }`
                                        }
                                        onClick={() => {
                                            if (
                                                selectedDate
                                                && selectedDate.getFullYear() === date.getFullYear()
                                                && selectedDate.getMonth() === date.getMonth()
                                                && selectedDate.getDate() === number
                                            ) {
                                                setSelectedDate(null)
                                            } else {
                                                setSelectedDate(new Date(date.getFullYear(), date.getMonth(), number))
                                            }
                                        }}
                                    >
                                        {number}
                                    </td>
                                )
                            } else {
                                newDays += 1;
                                return (
                                    <td
                                        className={`calendar__disabled calendar__cell
                                            ${
                                                selectedDate?.getDate() === number
                                                && selectedDate.getMonth() === date.getMonth()
                                                && selectedDate.getFullYear() === date.getFullYear() && 'active'
                                            }`
                                        }
                                    >
                                        {newDays}
                                    </td>
                                )
                            }
                        })  
                    }
                </tr>
            )
        })
    }


    return (
        <div className="calendar">
            <div className="calendar__poopup">
                {
                    activeContent === 'Date' && (
                        <>
                            <div className="calendar__header">
                                <div
                                    className="calendar__header__arrow prevmonth"
                                    onClick={() => {
                                        setDate(new Date(date.getFullYear(), date.getMonth() - 1))
                                    }}
                                >
                                    <ArrowNoFill />
                                </div>
                                <div onClick={() => setDate(new Date(date.getFullYear(), date.getMonth() + 1))}>{month[date.getMonth()]} {date.getFullYear()}</div>
                                <div
                                    className="calendar__header__arrow"
                                    onClick={() => {
                                        setDate(new Date(date.getFullYear(), date.getMonth() + 1))
                                    }}
                                >
                                    <ArrowNoFill />
                                </div>
                            </div>
                            <div className="calendar__body">
                                <table className="calendar__table">
                                    <tr className="calendar__row">
                                        {
                                            days.map((day) => (
                                                <td
                                                    className="calendar__cell calendar__disabled calendar__days"
                                                >
                                                    {day}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className="calendar__row">
                                        {
                                            firstRow()
                                        }
                                    </tr>
                                    {
                                    anotherRows()
                                    }
                                </table>
                            </div>
                            {
                                !disableTime && (
                                    <div className="calendar__time__wrapper">
                                        <div
                                            className="calendar__time__field"
                                            onClick={() => {
                                                setTime({minutes: 0, hours: new Date().getHours()})
                                                setActiveContent('Time');
                                            }}
                                        >
                                            <Time />
                                            {
                                                time.isSet
                                                    ? `${toHumanTime(time.hours, time.minutes)}`
                                                    : 'Выберите время'
                                            }
                                        </div>
                                        <div onClick={() => {
                                            setTime({...time, isSet: false})
                                        }} className="calendar__time__reset">Сбросить</div>
                                    </div>
                                )
                            }
                        </>
                    )
                }
                {
                    activeContent === 'Time' && (
                        navigator.appVersion.indexOf('Android') !== -1
                        || navigator.appVersion.indexOf('iPhone') !== -1 ? (
                            <div>
                                <div className="calendar__header center">
                                    <div>Выберите время</div>
                                </div>
                                <div className="calendar__body">
                                    <div className="calendar__radian">
                                        <div className="calendar__time__container">
                                            <CalendarList
                                                startedHour={time.hours}
                                                onSetTime={(time) => {
                                                    setTime((prev) => ({...prev, hours: time}))
                                                }}
                                            />
                                        </div>
                                        <div className="calendar__time__divider">:</div>
                                        <div className="calendar__time__container">
                                            <CalendarList
                                                isMinutes
                                                onSetTime={(time) => {
                                                    setTime((prev) => ({...prev, minutes: time}))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="calendar__header center">
                                    <div>Выберите время</div>
                                </div>
                                <div className="calendar__body">
                                    <div className="calendar__time__container">
                                        <input
                                            className="calendar__header__input"
                                            type="number"
                                            value={`${time.hours > 9 ? time.hours : `0${time.hours}`}`}
                                            onScroll={(e) => e.preventDefault()}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if(parseInt(value) > 23) {
                                                    setTime((prev) => ({...prev, hours: 23}))
                                                    console.log('timevalue:', time)
                                                } else if(value < 0){
                                                    setTime((prev) => ({...prev, hours: `00`}))
                                                } else {
                                                    setTime((prev) => ({...prev, hours: parseInt(value)}))
                                                }
                                            }}
                                        />
                                        <div className="calendar__header__label">Часы</div>
                                    </div>
                                    <div className="calendar__time__divider">:</div>
                                    <div className="calendar__time__container">
                                        <input
                                            type="number"
                                            className="calendar__header__input"
                                            value={`${time.minutes > 9 ? time.minutes : `0${time.minutes}`}`}
                                            onScroll={(e) => e.preventDefault()}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if(parseInt(value) > 59) {
                                                    setTime((prev) => ({...prev, minutes: 59}))
                                                } else if(value < 0){
                                                    setTime((prev) => ({...prev, minutes: `00`}))
                                                } else {
                                                    setTime((prev) => ({...prev, minutes: parseInt(value)}))
                                                }
                                            }}
                                        />
                                        <div className="calendar__header__label">Минуты</div>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
                <div className="calendar__buttons">
                    <div
                        className="calendar__button"
                        onClick={() => {
                            if (activeContent === 'Time') {
                                setTime({...time, isSet: false});
                                setActiveContent('Date');
                            } else {
                                closeModal();
                            }
                        }}
                    >
                        Закрыть
                    </div>
                    <div
                        className={`calendar__button ${selectedDate || activeContent === 'Time' ? 'bold' : 'disabled'}`}
                        onClick={() => {
                            if (activeContent === 'Time') {
                                setTime({...time, isSet: true});
                                setActiveContent('Date');
                            } else {
                                closeModal();
                                console.log('time.isSet:', time.isSet);
                                if (time.isSet) {
                                    setGlobalDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), time.hours, time.minutes))
                                } else {
                                    setGlobalDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59))
                                }
                            }
                        }}
                    >Установить</div>
                </div>
            </div>
        </div>
    )

}