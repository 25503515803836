import React, { useEffect, useState } from "react"
import { useTelegram } from "../../hooks/useTelegram"
import './index.css'
import { useNavigate, useLocation } from "react-router-dom";

import Calendar from "../../images/Calendar";
import From from "../../images/From";
import config from "../../config";
import MainButton from "../../components/button";
import { useDispatch } from "react-redux";
import { setCurrentPage, setIsShowNotify } from "../../slices/appSlice";
import toHumanFullDate from "../../utils/toHumanFullDate";
import Links from "../../images/Links";
import Attach from "../../images/Attach";
import Dislike from "../../images/Dislike";
import Lottie from 'lottie-react'
import Thinking from '../../images/Thinking_Face.json'
import store from "../../store";


import BecomeBetter from "../../images/become_better.json"
import CheckingAnswer from "../../images/checking_answers.json"
import FivePoints from "../../images/five_point.json"
import FourPoints from "../../images/four_point.json"
import ThreePoints from "../../images/three_point.json"
import TwoPoints from "../../images/two_point.json"
import BottomNotify from "../../components/bottomNotify";

import { useAmplitude } from "../../hooks/useAmplitude";
import { useEnterDate } from "../../hooks/useEnterDate";



export default function DetailPageExpert(){
    const createObj = (obj) => {
        return {
            expert_name: currentTask.author_name,
            expert_id: currentTask.author_id,
            task_id: currentTask.uid,
            status_task: currentTask.status_id,
            ...obj,
        }
    }
    const navigate = useNavigate();
    let location = useLocation();
    const trackEvent = useAmplitude();
    const startedDate = useEnterDate();
    const page = store.getState().appSlice.currentPage;

    const [currentTask, setCurrentTask] = useState({});
    const [buttonParams, setButtonParams] = useState({});
    const [isSendedScroll, setIsSendenScroll] = useState(false);
    const [answers, setAnswers] = useState({});
    const [timer, setTimer] = useState()
    const [isSendedFeedback, setIsSendedFeedback] = useState();
    const [notyParam, setNotyParam] = useState({
        lottie: BecomeBetter,
        isInput: true,
        onClose: () => {
            trackEvent('skip_feedback_exptask', createObj({}))
        },
        onBlur: (e) => {
            trackEvent('click_feedback_exptask', createObj({
                message_volume: e.target.innerText.length
            }))
        },
        title: "Помогите нам стать лучше",
        subTitle: "расскажите, что стоит улучшить, чтобы вам было интереснее выполнять задания",
        buttons: [
            {
                title: 'Отправить отзыв',
                onClick: (e) => {
                    trackEvent('send_fedback_exptask', createObj({
                        message_volume: e.length,
                        button_state: 'enabled',
                    }))
                    sendFeedBack(2, e, currentTask.uid);
                    setIsSendedFeedback(2);
                    setTimeout(() => {
                        dispatch(setIsShowNotify({
                            isShow: false
                        }))
                    }, 1000)
                },
                type: 'blue',
            }
        ]
    });

    useEffect(() => {
        const scrollEv = (e) => {
            const { target } = e;
            if(target.scrollHeight - target.scrollTop < target.offsetHeight + 150 && !isSendedScroll) {
                setIsSendenScroll(true)
                trackEvent('scroll_exptask', createObj({
                    last_screen: true,
                    time: new Date() - startedDate,
                }))
            }
        }
        const root = document.getElementsByClassName('root__container')[0]
        root.addEventListener('scroll', scrollEv)

        return () => {
            root.removeEventListener('scroll', scrollEv)
        }
    // eslint-disable-next-line
    }, [isSendedScroll, currentTask])

    const types = {
        'video': 'видео',
        'guide': 'гайд',
        'article': 'статья',
    }

    const marks = {
        '5': {
            title: 'Отлично! 5 из 5',
            lottie: FivePoints,
            firstText: 'Ты отлично разбираешься в теме! Надеюсь, ты научишься применять эти супер-полезные навыки в жизни и лучше понять себя. Рекомендую посмотреть другие видео у меня на канале.',
            secondText: 'Давай строить привычку делать себя лучше. Подпишись на мои задачи и прокачивай себя каждую неделю!'
        },
        '4': {
            title: 'Хорошо! 4 из 5',
            lottie: FourPoints,
            firstText: 'Ты отлично разбираешься в теме! Надеюсь, ты научишься применять эти супер-полезные навыки в жизни и лучше понять себя. Рекомендую посмотреть другие видео у меня на канале.',
            secondText: 'Давай строить привычку делать себя лучше. Подпишись на мои задачи и прокачивай себя каждую неделю!'
        },
        '3': {
            title: 'Неплохо! 3 из 5',
            lottie: ThreePoints,
            firstText: 'Попробуй пересмотреть видео и материалу к заданию, возможно, ты просто отвлекся и упустил важные детали.',
            secondText: 'Давай строить привычку делать себя лучше. Подпишись на мои задачи и прокачивай себя каждую неделю!'
        },
        '2': {
            title: 'Попробуй еще раз! \n 2\u00A0из\u00A05',
            lottie: TwoPoints,
            firstText: 'Попробуй пересмотреть видео и материалу к заданию, возможно, ты просто отвлекся и упустил важные детали.',
            secondText: 'Давай строить привычку делать себя лучше.'
        },
        '1': {
            title: 'Попробуй еще раз! \n 2\u00A0из\u00A05',
            lottie: TwoPoints,
            firstText: 'Попробуй пересмотреть видео и материалу к заданию, возможно, ты просто отвлекся и упустил важные детали.',
            secondText: 'Давай строить привычку делать себя лучше.'
        },
        '0': {
            title: 'Попробуй еще раз! \n 2\u00A0из\u00A05',
            lottie: TwoPoints,
            firstText: 'Попробуй пересмотреть видео и материалу к заданию, возможно, ты просто отвлекся и упустил важные детали.',
            secondText: 'Давай строить привычку делать себя лучше.'
        },
    }

    const dispatch = useDispatch();

    const { tg } = useTelegram();

    const backFunction = () => {
        const arr = page.split('/');
        if(arr.find(el => el === 'expertDetail')) {
            navigate(`/expertDetail/${arr[arr.length - 1]}`);
        } else {
            navigate('/');
        }
        dispatch(setIsShowNotify({
            isShow: false,
        }))
    }


    const sendFeedBack = (grade, text = '', uid) => {
        console.log('sendFeedBack:', grade, text, uid, currentTask);
        fetch(`${config.src}/supertasks/feedback/${uid ? uid : currentTask.uid}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                feedback_grade: grade,
                feedback_desc: text,
            })
        })
        .then(async (res) => {
            const json = await res.json();
            setCurrentTask({...currentTask, ...json})
        })
    }

    const takeTask = () => {
        trackEvent('take_task_in_work', {
            status: true,
        })
        fetch(`${config.src}/supertasks/move/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uid: currentTask.uid,
                executor_id: currentTask.executor_id,
                status_id: 'inprogress',
            })
        })
        .then(async (res) => {
            const json = await res.json();
            if(res?.subscription === 'payment') {
                navigate('/subscribe')
            } else {
                setCurrentTask({...currentTask, ...json})
                trackEvent('take_on_exptask', createObj({
                    button_state: 'enabled',
                    success_operation: true,
                }))
            }
        })
        .catch(() => {
            trackEvent('take_on_exptask', createObj({
                button_state: 'enabled',
                success_operation: false,
            }))
        })
    }

    const sendAnswers = () => {
        if(currentTask?.status_id === 'inprogress' && Object.keys(answers).length !== currentTask.questions.length) {
            trackEvent('answer_exptask', createObj({
                button_state: 'disabled',
                success_operation: false,
                questions_volumes: {...Object.values(answers).map((el) => el.answer.length)}
            }))
        } else {
            fetch(`${config.src}/supertasks/move/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: currentTask.uid,
                    executor_id: currentTask.executor_id,
                    status_id: 'answered',
                    answers: [...Object.values(answers)]
                })
            })
            .then(async (res) => {
                const json = await res.json();
                setCurrentTask({...currentTask, ...json});
                trackEvent('answer_exptask', createObj({
                    button_state: 'enabled',
                    success_operation: true,
                    questions_volumes: {...Object.values(answers).map((el) => el.answer.length)},
                }))
            })
            .catch(() => {
                trackEvent('answer_exptask', createObj({
                    button_state: 'enabled',
                    success_operation: false,
                    questions_volumes: {...Object.values(answers).map((el) => el.answer.length)},
                }))
            })
        }
        
    }

    const checkAnswers = () => {
        fetch(`${config.src}/supertasks/move/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uid: currentTask.uid,
                executor_id: currentTask.executor_id,
                status_id: 'done',
            })
        })
        .then(async (res) => {
            const json = await res.json();
            trackEvent('show_result_exptask', createObj({}))
            setCurrentTask({...currentTask, ...json})
        })
    };

    const closeTask = () => {
        setNotyParam({
          lottie: Thinking,
          isInput: false,
          onClose: () => {},
          title: 'Задача будет закрыта!',
          subTitle: 'После закрытия задачи вы больше не сможете работать над ней. Вы уверены?',
          buttons: [
            {
                title: 'Завершить',
                onClick: () => {
                    fetch(`${config.src}/supertasks/move/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            uid: currentTask.uid,
                            executor_id: currentTask.executor_id,
                            status_id: "closed"
                        })
                    })
                    .then(async (res) => {
                        dispatch(setIsShowNotify({
                            isShow: false
                        }))
                        navigate('/')
                    })
                },
                type: 'blue',
            },
            {
                title: 'Отменить',
                onClick: () => {
                    dispatch(setIsShowNotify({
                        isShow: false
                    }))
                },
            }
          ]
        })
        dispatch(setIsShowNotify({
            isShow: true,
        }))
    }


    const [propsList, setPropsList] = useState([
        {
            icon: <Calendar />,
            text: 'сегодня',
            id: 'date',
        },
        {
            icon: <From />,
            text: 'от кого',
            id: 'from',
        },
        {
            text: '≈0',
            id: 'time',
        },
    ]);

    useEffect(() => {
        dispatch(setCurrentPage(window.location.href))
        tg.BackButton.onClick(backFunction)
        tg.BackButton.show()

        return () => {
            tg.BackButton.offClick(backFunction)
        }
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const status_id = currentTask?.status_id
        if(status_id === 'todo') {
            setButtonParams({
                text: 'Взять в работу',
                lottie: false,
                onClick: takeTask,
                onSecondClick: closeTask,
            })
        }
        if(status_id === 'inprogress') {
            setButtonParams({
                text: 'Отправить ответы',
                lottie: false,
                onClick: sendAnswers,
                onSecondClick: closeTask,
            })
        }
        if(status_id === 'answered') {
            setButtonParams({
                text: 'Мы уже проверяем ваши ответы и скоро вернемся с результатами',
                onClick: () => {
                    trackEvent('check_answers_banner_exptask', createObj({
                        status: true,
                    }))
                },
                lottie: CheckingAnswer,
                green: false,
            })
        }

        if(status_id === 'resultsent') {
            setButtonParams({
                text: 'Посмотреть результаты',
                onClick: checkAnswers,
                lottie: false,
                green: true,
            })
        }

        if(status_id === 'expired') {
            setButtonParams({
                text: 'Закрыть задачу',
                onClick: closeTask,
                lottie: false,
                green: false,
                onSecondClick: false,
            })
        }

        if(status_id === 'done') {
            setButtonParams({
                text: 'Хочу еще задачи',
                onClick: () => {
                    trackEvent('want_more_task_exptask', createObj({}))
                },
                lottie: false,
                green: false,
                onSecondClick: false,
            })
        }
    // eslint-disable-next-line
    }, [currentTask, answers])

    useEffect(() => {
        let ur = 'id'
        if(page.split('/').find(el => el === 'expertDetail')) ur = 'main';
        const id = location.pathname.split('/')[2];
        fetch(`${config.src}/supertasks/${ur}/${id}/`, {
            method: 'GET'
        })
        .then(async (res) => {
            const json = await res.json();
            const newCurrentTask = {...json, deadline_date: new Date(json.deadline_date)};
            setCurrentTask(newCurrentTask);
            let obj = {};
            newCurrentTask.questions.filter((el) => el.answer).forEach((element) => {
                obj[element.uid] = {
                    question_uid: element.uid,
                    usertask_uid: newCurrentTask.uid,
                    answer: element.answer,
                }
            })
            setAnswers({...obj})
            setIsSendedFeedback(newCurrentTask.feedback_grade)
            setPropsList((prev) => prev.map((element) => {
                if (element.id === 'from') {
                    return {...element, text: newCurrentTask.author_name || newCurrentTask.author_id, secondIcon: newCurrentTask.author_nickname?.[0]}
                }
                if(element.id === 'time') {
                    return {...element, text: `≈ ${newCurrentTask.execution_time} минут`}
                }

                return element
            }))
            console.log('newCurrentTask:', newCurrentTask.status_id === 'expired');
            if(newCurrentTask.status_id === 'expired'){
                setNotyParam({
                    lottie: BecomeBetter,
                    isInput: false,
                    title: "Задача просрочена",
                    subTitle: "К сожалению, работать над задачей больше не получится.  Но вы можете посмотреть материалы и выполнить задачу без проверки самостоятельно.",
                    buttons: [
                        {
                            title: 'Посмотреть задачу',
                            onClick: () => {
                                dispatch(setIsShowNotify({
                                    isShow: false,
                                }))
                            },
                            type: 'blue',
                        }
                    ]
                })

                setTimeout(() => {
                    dispatch(setIsShowNotify({
                        isShow: true,
                    }))
                }, 100)
            } else {
                setNotyParam({
                    lottie: BecomeBetter,
                    isInput: true,
                    onClose: () => {
                        trackEvent('skip_feedback_exptask', createObj({}))
                    },
                    onBlur: (e) => {
                        trackEvent('click_feedback_exptask', createObj({
                            message_volume: e.target.innerText.length
                        }))
                    },
                    title: "Помогите нам стать лучше",
                    subTitle: "расскажите, что стоит улучшить, чтобы вам было интереснее выполнять задания",
                    buttons: [
                        {
                            title: 'Отправить отзыв',
                            onClick: (e) => {
                                trackEvent('send_fedback_exptask', createObj({
                                    message_volume: e.length,
                                    button_state: 'enabled',
                                }))
                                sendFeedBack(2, e, newCurrentTask.uid);
                                setIsSendedFeedback(2);
                                setTimeout(() => {
                                    dispatch(setIsShowNotify({
                                        isShow: false
                                    }))
                                }, 1000)
                            },
                            type: 'blue',
                        }
                    ]
                })
            }
        })
        .catch((err) => {
            console.log(err);
        })
    // eslint-disable-next-line  
    }, [location.pathname])

    return(
        <div className="main_page">
            {
                Object.keys(currentTask).length > 0 && (
                    <>
                        <div className="header">
                            <div className="text header__title middle">{currentTask.name || `#${currentTask.id}`}</div>
                        </div>
                        <div className="content small__padding">
                            <div className="detail__wrapper">
                                <div
                                    className="detail__textarea small__margin"
                                >{currentTask.description}</div>
                                <div className="detail__chip__wrapper">
                                    {
                                        propsList.map((element) => {
                                            return element.id === 'date' ? (
                                                <label
                                                    className={`content__filter detail__filter disabled ${currentTask.status_id === 'expired' && 'outdated'}`}
                                                    key={element.id}
                                                >
                                                    {
                                                        element?.icon && (
                                                            <div className="content__filter__icon">{element.icon}</div>
                                                        )
                                                    }
                                                    <div className="content__filter__text">{`${toHumanFullDate(currentTask?.deadline_date)}`}
                                                    </div>
                                                </label>
                                            ) : (
                                                <div
                                                    className={`content__filter detail__filter disabled`}
                                                    key={element.id}
                                                >
                                                    {
                                                        element?.icon && (
                                                            <div className={`content__filter__icon`}>{element.icon}</div>
                                                        )
                                                    }
                                                    <div className="content__filter__text">{element.text}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="content__tasklist__title margin middle">Материалы</div>
                            <div className="material__container">
                                {
                                    currentTask.materials.map((material) => (
                                        <a
                                            className="material__elemenet"
                                            rel="noreferrer"
                                            download={material.category_id === 'guide'}
                                            target="_blank"
                                            href={material.link || material.file}
                                            onClick={() => {
                                                trackEvent('material_exptask', createObj({
                                                    material_type: material.category_id,
                                                }))
                                            }}
                                        >
                                            <div className={`material__chip ${material.category_id}`}>{types[material.category_id]}</div>
                                            <div className="material__title">{material.name}</div>
                                            <div className="material__link">
                                                {
                                                    material.img && (
                                                        //eslint-disable-next-line
                                                        <img src={material.img} className="material__picture"/>
                                                    )
                                                }
                                                {
                                                    material.category_id === 'article' && (
                                                        <span><Links /> Ссылка</span>
                                                    )
                                                }
                                                {
                                                    material.category_id === 'guide' && (
                                                        <span><Attach /> Файл</span>
                                                    )
                                                }
                                            </div>
                                        </a>
                                    ))
                                }
                            </div>
                            <div className="content__tasklist__title margin middle">Вопросы</div>
                            <div className="material__container">
                                {
                                    currentTask.questions.map((question, index) => (
                                        <div className="material__elemenet" key={question.uid}>
                                            <div className="material__title">{index + 1}.&nbsp;{question.question}</div>
                                            {
                                                currentTask.status_id !== 'todo' && currentTask.status_id !== 'expired' && currentTask.status_id !== 'senttouser' && currentTask?.status_id !== 'deadline' && currentTask?.status_id !== 'closed' && (
                                                    <div className="material__input__container">
                                                        <div
                                                            className={`material__input
                                                                ${question.resolution === 1 && 'green'}
                                                                ${question.resolution === 0 && 'neutral'}
                                                                ${question.resolution === 2 && 'red'}
                                                                ${(currentTask.status_id !== 'inprogress') && 'disabled'}
                                                            `}
                                                            contentEditable
                                                            onBlur={(e) => {
                                                                trackEvent('click_answer', createObj({
                                                                   question_number: index,
                                                                   message_volume: e.target.innerText.length
                                                                }))
                                                            }}
                                                            onInput={(e) => {
                                                                if(e.target.innerHTML.indexOf('<span') !== -1) {
                                                                    e.target.innerHTML = e.target.innerText;
                                                                }
                                                                if(timer) {
                                                                    clearInterval(timer);
                                                                }
                                                                const mass = {...answers};
                                                                if(e.target.innerText !== ''){
                                                                    mass[question.uid] = {
                                                                        question_uid: currentTask.questions[index].uid,
                                                                        usertask_uid: currentTask.uid,
                                                                        answer: e.target.innerText,
                                                                    };
                                                                } else {
                                                                    delete mass[question.uid]
                                                                }
                                                                setTimer(setTimeout(() => {
                                                                    fetch(`${config.src}/supertasks/answers/`, {
                                                                        method: 'POST',
                                                                        headers: {
                                                                            'Content-Type': 'application/json',
                                                                        },
                                                                        body: JSON.stringify({    
                                                                            question_uid : currentTask.questions[index].uid,
                                                                            usertask_uid : currentTask.uid,
                                                                            answer : e.target.innerText !== 'напиши свой ответ тут' ? e.target.innerText : ''
                                                                        })
                                                                    })
                                                                }, 1000))
                                                                setAnswers(mass);
                                                            }}
                                                        >
                                                            {question.answer !== '' && question.answer}
                                                        </div>
                                                        {(!answers[question.uid] || answers[question.uid].answer === '') && (<span>напиши свой ответ тут</span>) }
                                                    </div>
                                                )
                                            }
                                            {
                                                !!question.review && (
                                                    <div className="material__answer">
                                                        {question.review}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            {   
                                (currentTask.resolution_grade === 0 || currentTask.resolution_grade) && currentTask.status_id === 'done'  && (
                                    <div className="material__result">
                                        <div className="material__lottie">
                                            <Lottie
                                                animationData={marks[currentTask.resolution_grade].lottie}
                                                autoPlay
                                                loop={false}
                                            />
                                        </div>
                                        <div className={`material__points ${parseInt(currentTask.resolution_grade) < 4 && 'orange'}`}>{marks[currentTask.resolution_grade].title}</div>
                                        <div className="material__describtion">{marks[currentTask.resolution_grade].firstText}<br/><br/>{marks[currentTask.resolution_grade].secondText}</div>
                                        <div className="material__result__marks">
                                            <div
                                                className={`material__button material__like ${isSendedFeedback === 1 && 'active'}`}
                                                onClick={() => {
                                                    if(!isSendedFeedback) {
                                                        trackEvent('like_exptask', createObj({}))
                                                        setIsSendedFeedback(1);
                                                        sendFeedBack(1)
                                                    }
                                                }}
                                            ><Dislike /></div>
                                            <div
                                                className={`material__button material__dislike ${isSendedFeedback === 2 && 'active'}`}
                                                onClick={() => {
                                                    if(!isSendedFeedback) {
                                                        trackEvent('dislike_exptask', createObj({}))
                                                        dispatch(setIsShowNotify({
                                                            isShow: true
                                                        }))
                                                    }
                                                }}
                                            ><Dislike /></div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                currentTask?.status_id !== 'senttouser' && currentTask?.status_id !== 'deadline' && currentTask?.status_id !== 'closed' && currentTask?.status_id !== 'done' && (
                                    <MainButton
                                        relative
                                        big
                                        lottie={buttonParams?.lottie}
                                        disabled={(currentTask?.status_id === 'inprogress' && Object.keys(answers).length !== currentTask.questions.length)}
                                        cancelTouch={false}
                                        title={buttonParams?.text}
                                        green={buttonParams?.green}
                                        onClick={buttonParams?.onClick}
                                        secondClick={buttonParams?.onSecondClick}
                                    />
                                ) 
                            }
                        </div>
                    </>
                )
            }
            <BottomNotify
                {...notyParam}
            />
            {
                Object.keys(currentTask).length === 0 && (
                    <>
                        <div className="header">
                            <div className="header__title bones" />
                        </div>
                        <div className="content">
                            <div className="detail__wrapper bones">
                                <div className="detail__textarea" />
                                <div className="detail__chip__wrapper" />
                            </div>
                        </div>
                    </>
                ) 
            }
        </div>
    )
}