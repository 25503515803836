export default function dateForBackend(data, field, currentTimeZoneOffsetInHours, isSetTime = false) {
    if (isSetTime) {
        const hours = data.getHours() < 10 ? `0${data.getHours()}` : data.getHours();
        const minutes = data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes();
        const sec = data.getSeconds() < 10 ? `0${data.getSeconds()}` : data.getSeconds();
        return {
            [field]: `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}T${hours}:${minutes}:${sec}`,
            tz: currentTimeZoneOffsetInHours,
        }
    } else {
        return {
            [field]: `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}`,
            tz: currentTimeZoneOffsetInHours,
        }
    }
} 