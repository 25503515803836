export default function Globe() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2239_31150)">
        <path d="M9.99996 18.3333C14.6025 18.3333 18.3333 14.6025 18.3333 9.99996C18.3333 5.39746 14.6025 1.66663 9.99996 1.66663C5.39746 1.66663 1.66663 5.39746 1.66663 9.99996C1.66663 14.6025 5.39746 18.3333 9.99996 18.3333Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.66665 2.5H7.49998C5.87501 7.36797 5.87501 12.632 7.49998 17.5H6.66665M12.5 2.5C14.125 7.36797 14.125 12.632 12.5 17.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5 13.3333V12.5C7.36797 14.125 12.632 14.125 17.5 12.5V13.3333M2.5 7.49998C7.36797 5.87501 12.632 5.87501 17.5 7.49998" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_2239_31150">
        <rect width="20" height="20" />
        </clipPath>
        </defs>
        </svg>
    )
}
