export default function Attach(){
    return(
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_756_8444)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3174 1.47497C7.94158 0.850787 8.78816 0.500122 9.6709 0.500122C10.5536 0.500122 11.4002 0.850787 12.0244 1.47497C12.6486 2.09916 12.9992 2.94574 12.9992 3.82847C12.9992 4.71121 12.6486 5.55779 12.0244 6.18197L6.2674 11.939C6.08965 12.1168 5.87863 12.2578 5.64637 12.3541C5.41411 12.4504 5.16516 12.4999 4.91375 12.5C4.40599 12.5001 3.919 12.2984 3.5599 11.9395C3.20079 11.5805 2.999 11.0936 2.9989 10.5858C2.99881 10.0781 3.20043 9.59108 3.5594 9.23197L9.1444 3.64597L9.8524 4.35397L4.2664 9.93897C4.1815 10.0239 4.11415 10.1247 4.0682 10.2356C4.02226 10.3465 3.99861 10.4654 3.99861 10.5855C3.99861 10.7055 4.02226 10.8244 4.0682 10.9354C4.11415 11.0463 4.1815 11.1471 4.2664 11.232C4.3513 11.3169 4.45209 11.3842 4.56301 11.4302C4.67394 11.4761 4.79283 11.4998 4.9129 11.4998C5.03296 11.4998 5.15185 11.4761 5.26278 11.4302C5.37371 11.3842 5.4745 11.3169 5.5594 11.232L11.3164 5.47497C11.5326 5.25875 11.7041 5.00206 11.8212 4.71955C11.9382 4.43705 11.9984 4.13426 11.9984 3.82847C11.9984 3.52269 11.9382 3.2199 11.8212 2.93739C11.7041 2.65489 11.5326 2.3982 11.3164 2.18197C11.1002 1.96575 10.8435 1.79424 10.561 1.67722C10.2785 1.5602 9.97568 1.49997 9.6699 1.49997C9.36411 1.49997 9.06132 1.5602 8.77882 1.67722C8.49631 1.79424 8.23962 1.96575 8.0234 2.18197L2.0954 8.11097C1.40772 8.8158 1.0255 9.76322 1.03156 10.7479C1.03762 11.7326 1.43148 12.6753 2.12779 13.3716C2.82409 14.0679 3.76674 14.4617 4.75144 14.4678C5.73615 14.4739 6.68357 14.0917 7.3884 13.404L13.1454 7.64597L13.8534 8.35397L8.0954 14.111C7.20056 14.9736 6.00285 15.4506 4.75995 15.4391C3.51706 15.4277 2.32832 14.9288 1.44949 14.0499C0.57066 13.1709 0.0719795 11.9821 0.060741 10.7392C0.0495026 9.49632 0.526605 8.29868 1.3894 7.40397L7.3174 1.47397V1.47497Z" fill="#037EE5"/>
        </g>
        <defs>
        <clipPath id="clip0_756_8444">
        <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>
    )
}

