import React, { useEffect, useRef, useState } from 'react';
import ArrowNoFill from '../../images/Arrow_nofill';
import Critical from '../../images/Critical';
import toHumanFormat from '../../utils/toHumanFormat';
import Trash from '../../images/Trash';
import config from '../../config';
import './index.css'
import OutdatedFire from '../../images/OutdatedFire';

function TaskDefault(props){
    const {
        priority,
        text,
        isExpert = false,
        data,
        author = false,
        onClick = () => {},
        openModal = () => {},
        avatar,
        onDelete = () => {},
        uid,
        from = false,
        isStored = false,
        preventDelete = false,
        isOutdated = false,
    } = props;

    const [timer, setTimer] = useState(0);
    const [timeInterval, setTimeInterval] = useState();
    const [isDeleteElement, setIsDeleteElement] = useState(false);
    const [startPosition, setStartPosition] = useState({
        x: 0,
        y: 0,
        scroll: 0,
    })
    const ref = useRef(null);

    const checkDirection = (x, y) => {
        if(Math.abs(x - startPosition.x) > Math.abs(y - startPosition.y) ) {
            return true
        }
        return false
    }

    useEffect(() => {
        if(isDeleteElement) {
            fetch(`${config.src}/${isExpert ? 'supertask' : 'tasks'}/id/${uid}/`, {
                method: 'DELETE',
            })
                .then(() => {
                    setTimeout(() => {
                        onDelete();
                    }, 350)
                })
                .catch((err) => {
                    console.log('err');
                })
        }
    //eslint-disable-next-line
    }, [isDeleteElement])


    return (
        <div className={`content_simple_wrapper ${isDeleteElement && 'deleted'}`}>
            <div
                className={`content__task__container ${preventDelete && 'overflow_hidden'}`}
                ref={ref}
                key={uid}
                onClick={(e) => {
                    console.log('delta', startPosition.x, e.clientX);
                    if ((timer > 700 || Math.abs(startPosition.x - e.clientX) > 10) && !preventDelete ) {
                        return
                    };
                    onClick();
                }}
                onMouseDown={(e) => {
                    if(!preventDelete) {
                        if (e.cancelable) e.preventDefault();
                        setStartPosition({
                            x: e.clientX + ref.current.scrollLeft,
                            y: e.clientY,
                            scroll: ref.current.scrollLeft,
                        })
                        let timer = 0;
                        const interval = setInterval(() => {
                            timer += 50;
                            setTimer(timer);
                            if (timer > 500) {
                                openModal();
                                clearInterval(interval);
                            }
                        }, 50)
                        setTimeInterval(interval);
                    }
                }}
                onMouseMove={(e) => {
                    if(!preventDelete && (startPosition.x !== 0 || startPosition.y !== 0 || startPosition.scroll !== 0)) {
                        clearInterval(timeInterval);
                        const nowX = e.clientX
                        const nowY = e.clientY
                        const direction = checkDirection(nowX, nowY);
                        if (direction) {
                            ref.current.style.overflowX = 'scroll';
                            console.log('scroll:', nowX - startPosition.x);
                            ref.current.scrollTo({
                                left: startPosition.x - nowX
                            });
                        }
                    }
                }}
                onMouseUp={(e) => {
                    if(!preventDelete && (startPosition.x !== 0 || startPosition.y !== 0 || startPosition.scroll !== 0)) {
                        clearInterval(timeInterval)
                        if(ref.current) {
                            ref.current.style.overflowX = 'hidden';
                            setTimeout(() => {
                                const width = document.getElementById('root').clientWidth
                                if(ref.current.scrollLeft > 180) {
                                    setIsDeleteElement(true);
                                    ref.current.scrollTo({
                                        left: width - 22,
                                        behavior: "smooth"
                                    })
                                } else if(ref.current.scrollLeft > 90) {
                                    ref.current.scrollTo({
                                        left: 60,
                                        behavior: "smooth"
                                    })
                                } else {
                                    ref.current.scrollTo({
                                        left: 0,
                                        behavior: "smooth"
                                    })
                                }
                            }, 50)
                        }
                        setTimeout(() => {
                            setStartPosition({
                                x: 0,
                                y: 0,
                                scroll: 0,
                            })
                        }, 50)
                    }
                }}
                onMouseLeave={(e) => {
                    if(!preventDelete && (startPosition.x !== 0 || startPosition.y !== 0 || startPosition.scroll !== 0)) {
                        clearInterval(timeInterval)
                        if(ref.current) {
                            ref.current.style.overflowX = 'hidden';
                            setTimeout(() => {
                                const width = document.getElementById('root').clientWidth
                                if(ref.current.scrollLeft > 180) {
                                    setIsDeleteElement(true);
                                    ref.current.scrollTo({
                                        left: width - 22,
                                        behavior: "smooth"
                                    })
                                } else if(ref.current.scrollLeft > 90) {
                                    ref.current.scrollTo({
                                        left: 60,
                                        behavior: "smooth"
                                    })
                                } else {
                                    ref.current.scrollTo({
                                        left: 0,
                                        behavior: "smooth"
                                    })
                                }
                            }, 50)
                        }
                        setTimeout(() => {
                            setStartPosition({
                                x: 0,
                                y: 0,
                                scroll: 0,
                            })
                        }, 50)
                    }
                }}
                onTouchStart={(e) => {
                    if(!preventDelete) {
                        setStartPosition({
                            x: e.touches[0].clientX + ref.current.scrollLeft,
                            y: e.touches[0].clientY,
                            scroll: ref.current.scrollLeft,
                        })
                        let timer = 0;
                        const interval = setInterval(() => {
                            timer += 50;
                            setTimer(timer);
                            if (timer > 500) {
                                openModal();
                                clearInterval(interval)
                            }
                        }, 50)
                        setTimeInterval(interval);
                    }
                }}
                onTouchMove={(e) => {
                    if(!preventDelete) {
                        clearInterval(timeInterval);
                    }
                }}
                onTouchEnd={(e) => {
                    if(!preventDelete) {
                        clearInterval(timeInterval)
                        if(ref.current) {
                            const scrollLeft = ref.current.scrollLeft
                            ref.current.style.overflowX = 'hidden';
                            ref.current.scrollLeft = scrollLeft;
                            setTimeout(() => {
                                const width = document.getElementById('root').clientWidth
                                if(ref.current.scrollLeft > 180) {
                                    setIsDeleteElement(true);
                                    ref.current.scrollTo({
                                        left: width - 22,
                                        behavior: "smooth"
                                    })
                                } else if(ref.current.scrollLeft > 90) {
                                    ref.current.scrollTo({
                                        left: 60,
                                        behavior: "smooth"
                                    })
                                } else {
                                    ref.current.scrollTo({
                                        left: 0,
                                        behavior: "smooth"
                                    })
                                }
                                setTimeout(() => {
                                    ref.current.style.overflowX = 'scroll';
                                }, 500)
                            }, 10)
                        }
                    }
                }}
                onTouchCancel={(e) => {
                    if(!preventDelete) {
                        console.log(e);
                        clearInterval(timeInterval)
                    }
                }}
            >
                <div
                    className={`content__task ${priority !== 1 && 'small__task__container'}`}
                >
                    <div
                        className="content__task__avatar"
                    >{avatar}</div>
                    <div className="content__task__content">
                        {
                            (priority === 1 || isStored || isOutdated) && (
                                <div className="content__task__header">
                                    {
                                        isStored && (
                                            <div className="content__task__stored">выполнено</div>
                                        )
                                    }
                                    {
                                        isOutdated && (
                                            <div className="content__task__outdated"><OutdatedFire /> просрочено</div>
                                        )
                                    }
                                    {
                                        priority === 1 && (
                                            <div className="content__task__critical">
                                                <Critical /> важное
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            author && (
                                <div className="content__task__middle">
                                    <div className={`content__task__author ${navigator.appVersion.indexOf('Mac OS') !== -1 && 'content__task__text__macos'}`}>{author}</div>
                                </div>
                            )
                        }
                        <div className="content__task__middle">
                            <div dangerouslySetInnerHTML={{__html: text}} className={`content__task__text ${navigator.appVersion.indexOf('Mac OS') !== -1 && 'content__task__text__macos'}`}></div>
                        </div>
                        <div className="content__task__data">{from && 'до '}{typeof data === 'string' ? data : data.getDate()} {typeof data !== 'string' && toHumanFormat(data.getMonth())}</div>
                    </div>
                    <div className="content__task__arrow">
                        <ArrowNoFill />
                    </div>
                </div>
                <div className='task__delete__wrapper'>
                    <div className='task__delete__white'></div>
                    <div
                        onClick={() => {
                            fetch(`${config.src}/${isExpert ? 'supertask' : 'task'}/id/${uid}/`, {
                                method: 'DELETE',
                            })
                                .then(() => {
                                    setTimeout(() => {
                                        onDelete()
                                    }, 200)
                                })
                                .catch((err) => {
                                    console.log('err');
                                })
                        }}
                        className={`task__delete`}><Trash /></div>
                </div>
            </div>
        </div>
    ) 
}
const Task = React.memo(TaskDefault)
export default Task;