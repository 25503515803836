import React from "react";
import './index.css'
import Lottie from 'lottie-react'


export default function MainButton(props){
    const {
        title = '',
        onClick = () => {},
        disabled = false,
        green = false,
        relative = false,
        cancelTouch = true,
        lottie = false,
        big = false,
        yellow = false,
        white = false,
        noMargin = false,
        link = false,
        secondClick = false,
    } = props;

    return(
        link ? (
            <a
                href={link}
                onClick={onClick}
                target="blank"
                className={`main__button ${cancelTouch && 'cancelTouch'} ${big && 'big'} ${lottie && 'lottie'} ${disabled && 'disabled'} ${green && 'green'} ${relative && 'relative'} ${yellow && 'yellow'} ${white && 'white'} ${noMargin && 'noMargin'}`}
            >
                {
                    lottie && (
                        <div className="main__button__lottie">
                            <Lottie
                                animationData={lottie}
                                autoPlay
                                loop
                            />
                        </div>
                    )
                }
                {title}
            </a>
        ) : !secondClick ? (
            <div
                onClick={onClick}
                className={`main__button ${cancelTouch && 'cancelTouch'} ${big && 'big'} ${lottie && 'lottie'} ${disabled && 'disabled'} ${green && 'green'} ${relative && 'relative'} ${yellow && 'yellow'} ${white && 'white'} ${noMargin && 'noMargin'}`}
            >
                {
                    lottie && (
                        <div className="main__button__lottie">
                            <Lottie
                                animationData={lottie}
                                autoPlay
                                loop
                            />
                        </div>
                    )
                }
                {title}
            </div>
        ) : (
            <div className="main__button__flex">
                <div
                    onClick={onClick}
                    className={`main__button ${cancelTouch && 'cancelTouch'} ${big && 'big'} ${lottie && 'lottie'} ${disabled && 'disabled'} ${green && 'green'} ${relative && 'relative'} ${yellow && 'yellow'} ${white && 'white'} ${noMargin && 'noMargin'}`}
                >
                    {
                        lottie && (
                            <div className="main__button__lottie">
                                <Lottie
                                    animationData={lottie}
                                    autoPlay
                                    loop
                                />
                            </div>
                        )
                    }
                    {title}
                </div>
                <div className="main__button__second" onClick={secondClick}></div>
            </div>
        )
        
    )
}