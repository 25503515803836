export default function WordsNormalize(word, count, sex) {
    let end = []

    if (sex === 'female') {
        end = ['', 'а', 'и', 'и', 'и', '']
    } else {
        end = ['ов', '', 'а', 'а', 'а', 'ов']
    }

    return count > 5 ? `${word}${end[5]}` : `${word}${end[count]}`
}