import toHumanFormat from "./toHumanFormat";
import toHumanTime from "./toHumanTime";

export default function toHumanFullDate(date, preventText=false) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (parseInt(hours) === 23 && parseInt(minutes) === 59) {
        const nowDate = new Date()
        const nowYear = nowDate.getFullYear();
        const nowMonth = nowDate.getMonth();
        const nowDay = nowDate.getDate();
        if (
            nowYear === year
            && nowMonth === month
            && nowDay === day
            && !preventText
        ) {
            return 'Сегодня'
        }

        const nextDay = new Date(nowDate.getTime() + (24 * 60 * 60 * 1000));
        const tomorrowYear = nextDay.getFullYear();
        const tomorrowMonth = nextDay.getMonth();
        const tomorrowDay = nextDay.getDate();

        if (
            year === tomorrowYear
            && month === tomorrowMonth
            && day === tomorrowDay
        ) {
            return 'Завтра'
        }
    }

    return `${day} ${toHumanFormat(month)} | ${toHumanTime(hours, minutes)}`
}