export default function New () {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="5" />
            <path d="M5 6V2.5" stroke-linecap="round"/>
            <path d="M5 7.5V8" stroke-linecap="round"/>
        </svg>
    )
}

