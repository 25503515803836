import { useNavigate } from "react-router-dom";
import './index.css'
import Arrow from "../../images/Arrow";
import New from "../../images/New";
import Clock from "../../images/Clock";
import Complete from "../../images/Complete";
import OutdatedFire from "../../images/OutdatedFire";

export default function ExpertTask (props) {
    const {
        uid,
        name,
        status,
        date,
    } = props;

    const day = 1000 * 60 * 60 * 24;

    const chip = {
        'todo': {
            title: 'новая задача',
            icon: <New />,
            color: 'blue'
        },
        'inprogress': {
            title: 'в работе',
            icon: <Clock />,
            color: 'blue'
        },
        'answered': {
            title: 'на проверке',
            icon: <Clock />,
            color: 'blue'
        },
        'resultreview': {
            title: 'на проверке',
            icon: <Clock />,
            color: 'blue'
        },
        'resultsent': {
            title: 'проверено',
            icon: <Complete />,
            color: 'green'
        },
        'done': {
            title: 'выполнена',
            icon: <Complete />,
            color: 'green'
        },
        'closed': {
            title: 'закрыта',
            icon: <Complete />,
            color: 'green'
        },
        'expired': {
            title: 'просрочена',
            icon: <OutdatedFire />,
            color: ''
        },
        'deadline': {
            title: 'дедлайн скоро',
            icon: <Clock />,
            color: '',
        },
    }

    const chipValue = new Date() - date < day && new Date() - date > 0 && (status === 'inprogress' || status === 'todo') ? chip['deadline'] : chip[status]

    console.log('chipValue:', chipValue, status, new Date() - date < day && new Date() - date > 0);

    const navigate = useNavigate();

    return(
        <div className="experttask" onClick={() => {navigate(`/expert/${uid}`)}}>
            <div className={`experttask__chip ${chipValue.color}`}>
                <div className={`experttask__icon ${chipValue.title === 'просрочена' && 'noPath'}`}>{chipValue.icon}</div>
                {chipValue.title}
            </div>
            <div className="experttask__name">{name}</div>
            <div className="experttask__arrow">
                <Arrow />
            </div>
        </div>
    )
}