import { createSlice } from '@reduxjs/toolkit';

export const consumerCreditSlice = createSlice({
    name: 'userSlice',
    initialState: {
        first_name: '',
        last_name: '',
        username: '',
        telegram_id: 0,
        phone: '',
        language_code: 'ru',
        auto_tz: true,
        tz: 0,
    },
    reducers: {
        setUser: (state, action) => {
            const {
                first_name = '',
                last_name = '',
                username = '',
                telegram_id = 0,
                phone = '',
                language_code = 'ru',
                auto_tz = true,
                tz = 0,
            } = action.payload;

            state.first_name = first_name;
            state.last_name = last_name;
            state.username = username;
            state.telegram_id = telegram_id;
            state.phone = phone;
            state.language_code = language_code;
            state.auto_tz = auto_tz;
            state.tz = tz;
        },
        setUserName: (state, action) => {
            const name = action.payload;
            state.userName = name
        },
        setUserSurname: (state, action) => {
            const name = action.payload;
            state.surName = name
        },
        setTimezone: (state, action) => {
            const tz = action.payload;
            state.tz = tz;
        },
        setAutoTimezone: (state, action) => {
            const auto_tz = action.payload;
            state.auto_tz = auto_tz;
        }
    },
});

export const { setUserName, setUser, setUserSurname, setTimezone, setAutoTimezone } = consumerCreditSlice.actions;

export default consumerCreditSlice.reducer;