export default function toHumanFormat(number, isShort = false) {
    switch(number.toString()){
        case '0':
            return isShort ? 'янв' : 'января'
        case '1':
            return isShort ? 'фев' : 'февраля'
        case '2':
            return isShort ? 'мар' : 'марта'
        case '3':
            return isShort ? 'апр' : 'апреля'
        case '4':
            return isShort ? 'май' : 'мая'
        case '5':
            return isShort ? 'июн' : 'июня'
        case '6':
            return isShort ? 'июл' : 'июля'
        case '7':
            return isShort ? 'авг' : 'августа'
        case '8':
            return isShort ? 'сен' : 'сентября'
        case '9':
            return isShort ? 'окт' : 'октября'
        case '10':
            return isShort ? 'ноя' : 'ноября'
        case '11':
            return isShort ? 'дек' : 'декабря'
        default:
            return isShort ? 'дек' : 'декабря'
    }
}