import React, { useEffect, useState, useRef, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import Calendar from "../../images/Calendar";
import Critical from "../../images/Critical";
import Filter from "../../images/Fiilter"
// import From from "../../images/From";
// import People from "../../images/People"
// eslint-disable-next-line
import './index.css'
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line
import toHumanFormat from "../../utils/toHumanFormat";
import config from "../../config";
import CustomCalendar from "../../components/calendar";
import { useTelegram } from "../../hooks/useTelegram";
import ArchiveWhite from "../../images/ArchiveWhite";
import ArchiveBlue from "../../images/ArchiveBlue";
import BottomNotify from "../../components/bottomNotify";
import Lottie from 'lottie-react'
import ResetFilter from "../../images/resetFilter";
// eslint-disable-next-line
import prettySmile from '../../images/pretty_smile.json'
import close_eye from '../../images/close_eye.json'
import noFindedTask from '../../images/sixth_point.json'

import { useAmplitude } from "../../hooks/useAmplitude";
import ExpertTask from "../../components/expertTask";
import { setCurrentPage } from "../../slices/appSlice";

export default function MainPage() {

    const state = useSelector((store) => store.userSlice);
    const app = useSelector((store) => store.appSlice);

    const delay = 1000;

    const isFirstLoad = useRef(0);

    
    // const [isAllActive, setIsAllActive] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);
    // eslint-disable-next-line
    const [activeFilters, setActiveFilter] = useState({});
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [expertTaskList, setExpertTaskList] = useState({});
    const [donedExpertTasks, setDonedExpertTask] = useState({});
    const [isOpenExpertList, setIsOpenExpertList] = useState(true);
    const [isLoadedContent, setIsLoadedContent] = useState(false);
    const [isOutdatedActive, setIsOutdatedActive] = useState(false);
    const [archiveTasks, setArchiveTasks] = useState([]);

    const trackEvent = useAmplitude();
    const dispatch = useDispatch();

    const ref = useRef(null);
    const closeEye = useRef(null);
    const smileRef = useRef(null);

    const src = config.src.split('/').splice(0, 3).join('/');

    useEffect(() => {
        if(ref.current) {
            ref.current.stop()
            setTimeout(() => {
                ref.current.play()
            }, 450)
        }
    //eslint-next-line-ignore
    }, []);
    
    useEffect(() => {
        if(app?.currentPage?.indexOf('expert') !== -1) {
            setIsOpenExpertList(true);
        }
        dispatch(setCurrentPage(window.location.href));
    // eslint-disable-next-line
    }, [app.currentPage])

    useEffect(() => {
        if(closeEye.current && isOpenExpertList) {
            closeEye.current.stop()
            setTimeout(() => {
                closeEye.current.play()
            }, 450)
        }
    //eslint-next-line-ignore
    }, [isOpenExpertList]);


    useEffect(() => {
        if(smileRef.current && activeFilters?.archive && archiveTasks.length) {
            smileRef.current.stop()
            setTimeout(() => {
                smileRef.current.play()
            }, 450)
        }
    //eslint-disable-next-line
    }, [activeFilters?.archive, archiveTasks.length]);

    const { tg } = useTelegram();

    const filtersList = [
        {
            icon: <Calendar />,
            text: 'дата',
            id: 'date',
        },
        {
            icon: <Critical />,
            text: 'важное',
            id: 'critical',
        },
        {
            icon: <ArchiveBlue />,
            text: 'архив',
            id: 'archive',
            activeIcon: <ArchiveWhite />
        },
        {
            icon: <ResetFilter />,
            id: 'resetFilter',
        },
        // {
        //     icon: <From />,
        //     text: 'от кого',
        //     id: 'from',
        //     secondIcon: <People />
        // },
        // {
        //     icon: <From />,
        //     text: 'кому',
        //     id: 'to',
        //     secondIcon: <People />
        // },
    ]
    
    const reducedFiltersList = [
        {
            icon: <Calendar />,
            text: 'дата',
            id: 'date',
        },
        {
            icon: <ArchiveBlue />,
            text: 'архив',
            id: 'archive',
            activeIcon: <ArchiveWhite />
        },
        {
            icon: <ResetFilter />,
            id: 'resetFilter',
        },
    ]

    const backBtnFunction = () => {
        setIsOutdatedActive(false);
    }

    const normalizeBackData = (json) => {
        return [...json]
        .map((element) => {
            return ({...element, deadline_date: new Date(element.deadline_date.replace(' ', 'T'))})
        })
        .sort((task1, task2) => task1.deadline_date > task2.deadline_date ? 1 : -1)
    }

    const loadClosedTask = () => {
        setIsLoadedContent(false);
        fetch(`${config.src}/tasks/closed/${state.telegram_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(async (res) => {
            const json = await res.json();
            if(json.length > 0) {
                setArchiveTasks([...normalizeBackData(json)])
            }
        })
        .catch((err) => {
            console.log('err:', err);
        })
        .finally(() => {
            setIsLoadedContent(true);
        })
    }

    useEffect(() => {
        if(!isOutdatedActive) {
            tg.BackButton.offClick(backBtnFunction)
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [isOutdatedActive])

    useEffect(() => {
        return() => {
            tg.BackButton.offClick(backBtnFunction)
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [])

    let navigate = useNavigate();

    const loadExpertsTasks  = () => {
        setIsLoadedContent(false);
        fetch(`${config.src}/supertasks/executor/${state.telegram_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(async (res) => {
            const json = await res.json();
            const normal = {};
            const doned = {};
            json.sort((a, b) => new Date(a.deadline_date) - new Date(b.deadline_date)).forEach((el) => {
                if(el.status_id === 'done' || el.status_id === 'closed'){
                    if(doned[el.author_id]) {
                        doned[el.author_id].push(el)
                    } else {
                        doned[el.author_id] = [el]
                    }
                } else {
                    if(normal[el.author_id]) {
                        normal[el.author_id].push(el)
                    } else {
                        normal[el.author_id] = [el]
                    }
                }
            })
            setDonedExpertTask({...doned})
            setExpertTaskList({...normal});
            setTimeout(() => {
                setIsLoadedContent(true);
            }, 200)
        })
    }

    useEffect(() => {
        if(state.telegram_id && isFirstLoad.current === 0) {
            setIsLoadedContent(false);
            isFirstLoad.current = isFirstLoad.current + 1;
            setTimeout(() => {
                loadExpertsTasks();
            }, delay)
        }
    // eslint-disable-next-line
    }, [state.telegram_id]);

    useEffect(() => {
        removeElement(['date', 'critical']);
        if (activeFilters?.archive && !isOpenExpertList) {
            loadClosedTask();
        }
    // eslint-disable-next-line
    }, [activeFilters?.archive])

    const removeElement = (id) => {
        const newCurrentFilters = Object.assign(activeFilters, {})
        if(typeof id === 'string') {
            delete newCurrentFilters[id];
        } else {
            id.forEach(element => {
                delete newCurrentFilters[element];
            })
        }
        setActiveFilter({...newCurrentFilters});
    }

    const renderExpertTask = useCallback((obj) => {
        let isExistTask = false;
        return (
            <>
                {
                    Object.values(obj).map((el) => {
                        const filtredMass = el
                            .filter((taskListElement) => {
                                const date = new Date(taskListElement.deadline_date);
                                return activeFilters.date ? date.getDate() === activeFilters.date.getDate()
                                && date.getMonth() === activeFilters.date.getMonth()
                                && date.getFullYear() === activeFilters.date.getFullYear() : true
                            })
                            if(filtredMass.length > 0 && !isExistTask) {
                                isExistTask = true
                            }
                        return (
                            <>
                                {
                                    filtredMass.length > 0 && (
                                        <div className="expert__wrapper">
                                            <div className="expert__header">
                                                <div style={{backgroundImage: el[0]?.expert?.photo ? `url(${src}/avatars/${el[0]?.expert?.photo})` : 'none'}} className="expert__avatar">{ !el[0]?.expert?.photo && el[0].author_name[0]}</div>
                                                <div className="expert__content">
                                                    <div className="expert__name">{el[0].author_name}</div>
                                                    <div className="expert__description">{el[0]?.expert?.expert_description ? el[0]?.expert?.expert_description : 'Эксперт в области бизнеса и личностного роста'}</div>
                                                </div>
                                            </div>
                                            {
                                                
                                                filtredMass.map((task) => (
                                                    <ExpertTask
                                                        uid={task.uid}
                                                        name={task.name}
                                                        status={task.status_id}
                                                        deadlineDate={task.deadline_date}
                                                    />
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </>
                        )
                    })
                }
                {
                    !isExistTask && (
                        <div className="content__noTasks">
                            <div className="content__noTasks__lottie">
                                <Lottie
                                    lottieRef={noFindedTask}
                                    animationData={prettySmile}
                                    autoPlay={false}
                                    loop={false}
                                />
                            </div>
                            <div className="content__noTasks__text">
                                По заданным критериям задач <br /> не найдено
                            </div>
                        </div>
                    )
                }
            </>
        )
    // eslint-disable-next-line
    }, [activeFilters.date])

    return(
        <div className="main_page">
            <div className="header">
                {
                    isOutdatedActive && <div style={{ fontFamily: 'Arial, sans-serif' }} className="text header__title">Просроченные</div>
                }
                {
                    !isOutdatedActive && (
                        <div className="header__filters">
                            <div
                                // onClick={() => setIsPersonalActive(!isPersonalActive)}
                                className={`header__icon icon__avatar`} onClick={() => {
                                    navigate('/lk')
                                }}>{state.photo ? state.photo : state.first_name[0]?.toUpperCase() }</div>
                            {/* <div
                                onClick={() => setIsAllActive(!isAllActive)}
                                className={`header__icon icon__all ${isAllActive && 'active'}`}><People /></div> */}
                            <div className={`header__tabs`}>
                                <div
                                    className={`header__tab active`}
                                >Экспертные</div>
                                <div
                                    className={`header__tab`}
                                    onClick={() => {
                                        navigate('/marketplace')
                                    }}
                                >Маркетплейс</div>
                            </div>
                            <div
                                onClick={() => {
                                    if(isFilterActive) {
                                        trackEvent('filter', {
                                            status: false,
                                            date: activeFilters.date ? 'custom' : 'default',
                                            critical: activeFilters.critical ? 'custom' : 'default',
                                            archive: activeFilters.archive ? 'custom' : 'default',
                                        });
                                    } else {
                                        trackEvent('filter', {
                                            status: true,
                                            date: activeFilters.date ? 'custom' : 'default',
                                            critical: activeFilters.critical ? 'custom' : 'default',
                                            archive: activeFilters.archive ? 'custom' : 'default',
                                        });
                                    }
                                    setIsFilterActive(!isFilterActive)
                                }}
                                className={`header__icon icon__filter ${isFilterActive && 'active'}`}><Filter /></div>
                        </div>
                    )
                }
                
            </div>
            <div className="content">
                {
                    (isFilterActive) && (
                        <div className="content__filters__overflow">
                            <div className="content__filters">
                                {
                                    (isOpenExpertList ? reducedFiltersList : filtersList).map((filterElement) => {
                                        return filterElement.id === 'date' ? (
                                            <div
                                                className={`content__filter ${activeFilters[filterElement.id] && 'active'}`}
                                                key={filterElement.id}
                                                onClick={() => {
                                                    if (!activeFilters[filterElement.id]) {
                                                        setIsCalendarOpen(true);
                                                        trackEvent('date', {status: true});
                                                    } else if(activeFilters[filterElement.id]) {
                                                        removeElement(filterElement.id)
                                                        trackEvent('date', {status: true});
                                                    }
                                                }}
                                            >
                                                {
                                                    filterElement?.icon && (
                                                        <div className="content__filter__icon">{filterElement.icon}</div>
                                                    )
                                                }
                                                <div className="content__filter__text">{activeFilters[filterElement.id] ? `${activeFilters[filterElement.id].getDate()} ${toHumanFormat(activeFilters[filterElement.id].getMonth())}` : filterElement.text}</div>
                                                {
                                                    filterElement?.secondIcon && (
                                                        <div className="content__filter__icon">{filterElement.secondIcon}</div>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <div
                                                className={`content__filter ${activeFilters[filterElement.id] && 'active'} ${!filterElement.text && 'solo_icon'} ${filterElement.id === 'archive' && 'special__styles'}`}
                                                key={filterElement.id}
                                                onClick={() => {
                                                    if(filterElement.id === 'resetFilter'){
                                                        trackEvent('reset_filter', {
                                                            status: true,
                                                        })
                                                        if(Object.keys(activeFilters).length > 0) {
                                                            removeElement([...Object.keys(activeFilters)])
                                                        }
                                                    } else {
                                                        if (activeFilters[filterElement.id]) {
                                                            removeElement(filterElement.id)
                                                            trackEvent(`${filterElement.id}`, {status: true})
                                                        } else {
                                                            setActiveFilter({...activeFilters, [filterElement.id]: true})
                                                            trackEvent(`${filterElement.id}`, {status: true})
                                                        }
                                                    }
                                                }}
                                            >
                                                {
                                                    filterElement?.icon && (
                                                        <div className={`content__filter__icon ${!filterElement.text && 'solo_icon'}`}>{filterElement.icon}</div>
                                                    )
                                                }
                                                <div className="content__filter__text">{filterElement.text}</div>
                                                {
                                                    filterElement?.secondIcon && (
                                                        <div className="content__filter__icon">{filterElement.secondIcon}</div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    )
                                }
                            </div>
                        </div>
                    )
                }
                {
                    isOpenExpertList && Object.keys(donedExpertTasks).length > 0 && activeFilters?.archive && !activeFilters?.critical && !isOutdatedActive && renderExpertTask(donedExpertTasks)
                }
                {
                    isOpenExpertList && Object.keys(expertTaskList).length > 0 && !activeFilters?.archive && !activeFilters?.critical && !isOutdatedActive && renderExpertTask(expertTaskList)
                }
                {
                    Object.keys(expertTaskList).length === 0 && !activeFilters?.archive && isOpenExpertList && isLoadedContent && (
                            <div className="content__noTasks">
                                <div className="content__noTasks__lottie">
                                    <Lottie
                                        lottieRef={closeEye}
                                        animationData={close_eye}
                                        autoPlay={false}
                                        loop={false}
                                    />
                                </div>
                                <div className="content__noTasks__text">
                                    Пока&nbsp;от&nbsp;эксперта&nbsp;нет&nbsp;задач.<br />
                                    Новые задачи можно получить, подписавшись на эксперта.
                                </div>
                            </div>
                    )
                }
                {
                    Object.keys(expertTaskList).length === 0 && !isLoadedContent && (
                        <div className="content__tasks">
                            <div className="content__task__wrapper">
                                <div className="content__tasklist__title bones"></div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist__title bones"></div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    )
                }
            </div>
            <BottomNotify />
            {
                isCalendarOpen && (
                    <CustomCalendar
                        closeModal={() => {setIsCalendarOpen(false)}}
                        isOpen={isCalendarOpen}
                        setDate={(date) => setActiveFilter({...activeFilters, date})}
                        disableTime
                        isVisibleToday
                    />
                )
            }
        </div>
    )
}