import * as amplitude from '@amplitude/analytics-browser';
amplitude.init('1be637ac27b26b974a0731c50018de2c');

export function useAmplitude() {

    const trackEvent = (str, obj = {}) => {
        amplitude.track(str, {...obj, test: true})
    }

    return trackEvent

}