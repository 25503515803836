import { useEffect, useState } from 'react'
import MainButton from '../../components/button'
//eslint-disable-next-line
import Bell from '../../images/Bell'
import Globe from '../../images/Globe'
// eslint-disable-next-line
import Refresh from '../../images/Refresh'
import './index.css'
import { useTelegram } from '../../hooks/useTelegram'
import Zap from '../../images/Zap'
//eslint-disable-next-line
import { useNavigate } from "react-router-dom";
import config from '../../config'
import { useAmplitude } from '../../hooks/useAmplitude'
import BottomNotify from '../../components/bottomNotify'
import { useSelector } from 'react-redux'
import ArrowNoFill from '../../images/Arrow_nofill'

export default function LkPage() {
    // eslint-disable-next-line 
    const [image, setImage] = useState();
    const [status, setStatus] = useState()
    let navigate = useNavigate();

    const trackEvent = useAmplitude()

    const { tg } = useTelegram();

    const callback = () => {
        navigate('/')
    }

    useEffect(() => {
        tg.BackButton.onClick(callback)
        tg.BackButton.show()

        return () => {
            tg.BackButton.offClick(callback)
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [tg]);

    const user = useSelector((store) => store.userSlice)

    useEffect(() => {
        const date = new Date();
        if(user.telegram_id) {
            fetch(`${config.src}/subcribe/status/${user.telegram_id}/`)
                .then(async (res) => {
                    let re = await res.json();
                    const differentDate = new Date() - date;
                    setTimeout(() => {
                        setStatus(re);
                    }, 1000 - differentDate)
                })
        }
    }, [user])

    const tabs = [
        // {
        //     icon: <Bell />,
        //     onClick: () => {
        //         navigate('/notify')
        //     },
        //     text: 'Уведомления',
        // },
        {
            icon: <Globe />,
            onClick: () => {
                navigate('/changeTimezone')
            },
            text: 'Часовой пояс',
        }
    ]


    return (
        <div className="lk">
            {
                status ? (
                    <>
                        <div className="lk__header">
                            <div className="lk__avatar" style={{background: image && `no-repeat center/70% url(${image})`}}>
                                {
                                    !image && user.first_name[0]
                                }
                                {/* <label className="lk__avatar__update">
                                    <input type="file" className="lk__avatar__input" onChange={(e) => {
                                        const f = e.target.files[0];
                                        console.log('image1:', f)
                                        if(f){
                                            const fr = new FileReader();
                                            fr.onload = ()=>{
                                                setImage(fr.result);
                                            }
                                            fr.readAsDataURL(f);
                                        }
                                    }}></input>
                                    <Refresh />
                                </label> */}
                            </div>
                            <div className="lk__header__content">
                                <div className="lk__name">{user?.first_name && `${user?.first_name} `}{user.last_name}</div>
                                <div className="lk__you">Это вы! :)</div>
                            </div>
                        </div>
                        <div className={`lk__block ${status.status === 'active' && 'yellow'}`}>
                            <div className="lk__block__icon">
                                <Zap />
                            </div>
                            <div className="lk__block__title">Waflee Premium</div>
                            {
                                status.status === 'active' ? (
                                    <div>
                                        <div className="lk__subscribed">
                                            <div className="lk__subscribed__element">Следующий платеж</div>
                                            <div className="lk__subscribed__element">{new Date(status.to_date).getDate()}.{new Date(status.to_date).getMonth() + 1 > 10 ? new Date(status.to_date).getMonth() + 1 : `0${new Date(status.to_date).getMonth() + 1}`}.{new Date(status.to_date).getFullYear()}</div>
                                        </div>
                                        <div className="lk__subscribed">
                                            <div className="lk__subscribed__element">Стоимость подписки</div>
                                            <div className="lk__subscribed__element">{status.price} <span className="rubble__class">₽</span></div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="lk__block__describtion">Подписка на задания от&nbsp;авторов&nbsp;экспертного контента! </div>
                                )
                            }
                            <MainButton
                                title="Подробнее"
                                relative
                                noMargin
                                big
                                yellow={status.status === 'active'}
                                white={status.status !== 'active'}
                                onClick={() => {
                                    trackEvent('more_about_subsription', {
                                        subscription_status: status,
                                    })
                                    navigate('/subscribe')
                                }}
                                cancelTouch={false}
                            />
                        </div>
                        {
                            tabs.map(({onClick, icon, text}, index) => (
                                <div key={index} className="lk__block lk__notification" onClick={onClick}>
                                    <div className="lk__block__icon">
                                        {icon}
                                    </div>
                                    <div className="lk__block__describtion">{text}</div>
                                    <div className="content__task__arrow">
                                        <ArrowNoFill />
                                    </div>
                                </div>
                            ))
                        }
                       
                    </>
                ) : (
                    <div className="lk__preloader">
                        <div className="lk__header bones">
                            <div className="lk__avatar bones" style={{backgroundImage: `url(${image})`}}>
                            </div>
                        </div>
                        <div className="lk__block bones">
                        </div>
                        <div className="lk__block lk__notification bones" onClick={() => {navigate('/notify')}}>
                        </div>
                    </div>
                )
            }
            <BottomNotify />
        </div>
    )
}