import { createSlice } from '@reduxjs/toolkit';

export const consumerCreditSlice = createSlice({
    name: 'appSlice',
    initialState: {
        isOffScroll: false,
        currentPage: '/',
        notifyParams: {
            isShow: false,
            type: 'complete',
        },
    },
    reducers: {
        setIsScrollOff: (state, action) => {
            state.isOffScroll = action.payload
        },
        setIsShowNotify: (state, action) => {
            state.notifyParams.isShow = action.payload.isShow
            state.notifyParams.type = action.payload.type || state.notifyParams.type;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        }
    },
});

export const { setIsScrollOff, setIsShowNotify, setCurrentPage } = consumerCreditSlice.actions;

export default consumerCreditSlice.reducer;