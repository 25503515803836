import React, { useEffect, useState } from "react"
import { useTelegram } from "../../hooks/useTelegram"
import './index.css'
import { useNavigate, useLocation } from "react-router-dom";

import Calendar from "../../images/Calendar";
import { useAmplitude } from "../../hooks/useAmplitude";
import Critical from "../../images/Critical";
// import From from "../../images/From";
// import People from "../../images/People"
// eslint-disable-next-line
import tasks from "../../mockData/tasks";
import config from "../../config";
import MainButton from "../../components/button";
import dateForBackend from "../../utils/dateForBackend";
import CustomCalendar from "../../components/calendar";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowNotify } from "../../slices/appSlice";
import BottomNotify from "../../components/bottomNotify";
import toHumanFullDate from "../../utils/toHumanFullDate";


export default function DetailPageNormal(){
    const navigate = useNavigate();
    let location = useLocation();

    const trackEvent = useAmplitude();

    const [taskProps, setTaskProps] = useState({});
    const [currentTask, setCurrentTask] = useState({});
    const [currentDescription, setCurrentDescription] = useState('');
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isModifire, setIsModifire] = useState(false);
    const [isDateModifire, setIsDateModifire] = useState(false);
    const [buttonParams, setButtonParams] = useState({});

    const dispatch = useDispatch();

    const user =  useSelector((store) => store.userSlice);

    const { tg } = useTelegram();

    const backFunction = () => {
        navigate('/');
        dispatch(setIsShowNotify({
            isShow: false,
        }))
    }

    const saveTask = (description, date, priority) => {
        const id = location.pathname.split('/')[2];
        const datetForBackend = date ? dateForBackend(date, 'deadline_date',  user.tz, true) : dateForBackend(currentTask.deadline_date, 'deadline_date', user.tz, true);
        fetch(`${config.src}/tasks/id/${id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                author_id: user.telegram_id,
                description: description !== '' ? description : currentTask.description,
                executor_id: user.telegram_id,
                priority: priority ? 1 : 0,
                ...datetForBackend,
            })
        })
        .finally(() => {
            setIsModifire(false);
            navigate('/')
        })
    }

    const completeTask = () => {
        const id = location.pathname.split('/')[2];
        fetch(`${config.src}/tasks/id/${id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: 3,
            })
        })
        .finally(() => {
            setCurrentTask((prev) => ({...prev, status: 3}))
            dispatch(setIsShowNotify({
                type: 'complete',
                isShow: true,
            }))
        })
    };

    const takeTask = () => {
        trackEvent('take_task_in_work', {
            status: true,
        })
        const id = location.pathname.split('/')[2];
        fetch(`${config.src}/tasks/id/${id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: 2,
            })
        })
        .finally(() => {
            setCurrentTask((prev) => ({...prev, status: 2}))
        })
    };

    const removeElement = (id) => {
        const newCurrentFilters = Object.assign(taskProps, {})
        delete newCurrentFilters[id];
        setTaskProps({...newCurrentFilters});
    };

    const [propsList, setPropsList] = useState([
        {
            icon: <Calendar />,
            text: 'сегодня',
            id: 'date',
        },
        {
            icon: <Critical />,
            text: 'важное',
            id: 'critical',
        },
        // {
        //     icon: <From />,
        //     text: 'от кого',
        //     id: 'from',
        //     secondIcon: <People />
        // },
        // {
        //     icon: <From />,
        //     text: 'кому',
        //     id: 'to',
        //     secondIcon: <People />
        // },
    ]);

    useEffect(() => {
        tg.BackButton.onClick(backFunction)
        tg.BackButton.show()

        return () => {
            tg.BackButton.offClick(backFunction)
        }
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isModifire || isDateModifire) {
            setButtonParams({
                text: 'Сохранить задачу',
                green: true,
                onClick: () => {
                    trackEvent('task_editing', {
                        date: isDateModifire ? 'modifired' : 'original',
                        text: isModifire ? 'modifired' : 'original',
                        critical: isModifire ? 'modifired' : 'original',
                    })
                    saveTask(currentDescription, taskProps.date, taskProps.critical)
                },
            })
        } else if (!isModifire && !isDateModifire && currentTask.status){
            if (currentTask?.status === 2) {
                setButtonParams({
                    text: 'Завершить задачу',
                    onClick: () => {
                        completeTask();
                    }
                })
            } else {
                setButtonParams({
                    text: 'Взять в работу',
                    onClick: takeTask,
                    green: true,
                })
            }
        }
        // eslint-disable-next-line
    }, [isModifire, isDateModifire, currentTask.status, currentDescription, taskProps.date, taskProps.critical]);

    useEffect(() => {
        const id = location.pathname.split('/')[2];

        fetch(`${config.src}/tasks/id/${id}/`, {
            method: 'GET'
        })
        .then(async (res) => {
            const json = await res.json();
            const newCurrentTask = {...json, deadline_date: new Date(json.deadline_date)};
            setCurrentTask(newCurrentTask);

            if(newCurrentTask.priority === 1) {
                setTaskProps((prev) => ({...prev, critical: true}))
            }

            if (newCurrentTask.status === 2) {
                setButtonParams({
                    text: 'Завершить задачу',
                    onClick: () => {
                        completeTask();
                    },
                    green: true,
                })
            } else {
                setButtonParams({
                    text: 'Взять в работу',
                    onClick: takeTask,
                    green: true,
                })
            }
            setPropsList((prev) => prev.map((element) => {
                if (element.id === 'from') {
                    return {...element, secondIcon: newCurrentTask.author_nickname?.[0]}
                }

                if (element.id === 'to') {
                    return {...element, secondIcon: newCurrentTask.executor_name?.[0]}
                }

                return element
            }))
        })
        .catch((err) => {
            console.log(err);
        })
    // eslint-disable-next-line  
    }, [location.pathname])

    return(
        <div className="main_page">
            {
                Object.keys(currentTask).length > 0 && (
                    <>
                        <div className="header">
                            <div className="text header__title">{currentTask.name || `#${currentTask.id}`}</div>
                        </div>
                        <div className="content">
                            <div className="detail__wrapper">
                                <div
                                    className="detail__textarea"
                                    dangerouslySetInnerHTML={{__html: currentTask.description}}
                                    contentEditable={user?.telegram_id === currentTask.author_id && "true"}
                                    onInput={(e) => {
                                        if(!isModifire) {
                                            setIsDateModifire(true);
                                        }
                                        setCurrentDescription(e.target.innerHTML)
                                    }}
                                ></div>
                                <div className="detail__chip__wrapper">
                                    {
                                        propsList.map((element) => {
                                            return element.id === 'date' ? (
                                                <label
                                                    className={`content__filter detail__filter active ${user?.telegram_id !== currentTask.author_id && 'disabled' } `}
                                                    key={element.id}
                                                    onClick={() => {
                                                        setIsCalendarOpen(true);
                                                    }}
                                                >
                                                    {
                                                        element?.icon && (
                                                            <div className="content__filter__icon">{element.icon}</div>
                                                        )
                                                    }
                                                    <div className="content__filter__text">{taskProps[element.id]
                                                        ? `${toHumanFullDate(taskProps[element.id])}`
                                                        : `${toHumanFullDate(currentTask?.deadline_date)}`}
                                                    </div>
                                                    {
                                                        element?.secondIcon && (
                                                            <div className="content__filter__icon">{element.secondIcon}</div>
                                                        )
                                                    }
                                                </label>
                                            ) : (
                                                <div
                                                    className={`content__filter detail__filter
                                                        ${(element.id === 'from' || element.id === 'to' || user?.telegram_id !== currentTask.author_id) && 'disabled' }
                                                        ${taskProps[element.id] && 'active'}`
                                                    }
                                                    key={element.id}
                                                    onClick={() => {
                                                        if(!isModifire) {
                                                            setIsModifire(true);
                                                        }
                                                        if (taskProps[element.id]) {
                                                            removeElement(element.id)
                                                        } else {
                                                            setTaskProps({...taskProps, [element.id]: true})
                                                        }
                                                    }}
                                                >
                                                    {
                                                        element?.icon && (
                                                            <div
                                                                className={`
                                                                    content__filter__icon
                                                                    ${element.id === 'critical' && user?.telegram_id !== currentTask.author_id && !!taskProps.critical && 'red_icon'}
                                                                `}>{element.icon}</div>
                                                        )
                                                    }
                                                    <div className="content__filter__text">{element.text}</div>
                                                    {
                                                        element?.secondIcon && (
                                                            <div className="content__filter__icon">{element.secondIcon}</div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {
                Object.keys(currentTask).length === 0 && (
                    <>
                        <div className="header">
                            <div className="header__title bones" />
                        </div>
                        <div className="content">
                            <div className="detail__wrapper bones">
                                <div className="detail__textarea" />
                                <div className="detail__chip__wrapper" />
                            </div>
                        </div>
                    </>
                ) 
            }
            {
                buttonParams?.text && currentTask?.status !== 3 && (
                    <MainButton
                        title={buttonParams?.text}
                        green={buttonParams?.green}
                        onClick={buttonParams?.onClick}
                        cancelTouch={false}
                    />
                )
            }
            <BottomNotify />
            {
                isCalendarOpen && (
                    <CustomCalendar
                        closeModal={() => {setIsCalendarOpen(false)}}
                        isOpen={isCalendarOpen}
                        setDate={(date) => {
                            setTaskProps({...taskProps, date: date})
                            setIsDateModifire(true);
                        }}
                        isVisibleToday
                        isSetStartedValue
                        startedDate={currentTask?.deadline_date}
                    />
                )
            }
        </div>
    )
}