import React, {useEffect, useState} from "react"
import config from "../../config"
import { useDispatch } from "react-redux"
import MainButton from "../../components/button"
import { useNavigate } from "react-router-dom"
import { useTelegram } from "../../hooks/useTelegram"
import { setTimezone } from "../../slices/userSlice"
import { useSelector } from "react-redux";
import BottomNotify from "../../components/bottomNotify"
import "./index.css"
import Selected from "../../images/Selected"
import FormatTimezones from "../../utils/formatTimezones"

export default function ChangeTimezone() {
    const timezones = [
        {
            time: -11,
            name: 'Мидуэй',
        },
        {
            time: -10,
            name: 'Гавайи',
        },
        {
            time: -9,
            name: 'Адак',
        },
        {
            time: -8,
            name: 'Аляска',
        },
        {
            time: -7,
            name: 'Тихоокеанское время, Феникс, Тихуана',
        },
        {
            time: -6,
            name: 'Горное время, Гвадалахара, Мехико, Монтеррей',
        },
        {
            time: -5,
            name: 'Центральное время, Лима, Богота',
        },
        {
            time: -4,
            name: 'Восточное время, Джорджтаун, Сантьяго, Индианаполис',
        },
        {
            time: -3.50,
            name: 'Ньюфаундленд',
        },
        {
            time: -3,
            name: 'Бразилиа, Буэнос-Айрес, Сент-Джон',
        },
        {
            time: -2,
            name: 'Среднеатлантическое время',
        },
        {
            time: -1,
            name: 'Кабо-Верде',
        },
        {
            time: 0,
            name: 'Азорские острова',
        },
        {
            time: 1,
            name: 'Лондон, Дублин, Эдинбург, Лиссабон',
        },
        {
            time: 2,
            name: 'Берлин, Мадрид, Париж, Западная Центральная Африка',
        },
        {
            time: 3,
            name: 'Минск, Москва, Стамбул, Киев',
        },
        {
            time: 3.5,
            name: 'Тегеран',
        },
        {
            time: 4,
            name: 'Абу-Даби, Баку, Тбилиси',
        },
        {
            time: 4.5,
            name: 'Кабул',
        },
        {
            time: 5,
            name: 'Екатеринбург, Исламабад, Ташкент',
        },
        {
            time: 5.5,
            name: 'Мумбаи, Нью-Дели',
        },
        {
            time: 5.75,
            name: 'Катманду',
        },
        {
            time: 6,
            name: 'Алматы, Новосибирск, Дакка',
        },
        {
            time: 6.5,
            name: 'Рангун',
        },
        {
            time: 7,
            name: 'Красноярск, Бангкок, Ханой, Джакарта',
        },
        {
            time: 8,
            name: 'Иркутск, Гонконг, Пекин, Сингапур',
        },
        {
            time: 9,
            name: 'Якутск, Осака, Сеул, Токио',
        },
        {
            time: 9.5,
            name: 'Аделаида, Дарвин',
        },
        {
            time: 10,
            name: 'Владивосток, Мельбурн, Сидней',
        },
        {
            time: 11,
            name: 'Магадан, Новая Каледония',
        },
        {
            time: 12,
            name: 'Камчатка, Фиджи',
        },
        {
            time: 13,
            name: 'Самоа, Токелау',
        },
    ]
    const user = useSelector((store) => store.userSlice);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {tg} = useTelegram();
    const [currentTimezone, setCurrentTimezone] = useState({});
    const [selectedTimezone, setSelectedTimezone] =  useState({});
    const [otherTimezones, setOtherTimezones] = useState([])

    useEffect(() => {
        const backFunction = () => {
            navigate('/lk');
        }
        tg.BackButton.onClick(backFunction)
        tg.BackButton.show()

        return () => {
            tg.BackButton.offClick(backFunction)
        }
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(user.tz || user.tz === 0) {
            setCurrentTimezone(timezones.find((el) => el.time === user.tz));
            setOtherTimezones([...timezones.filter((el) => el.time !== user.tz)]);
            setSelectedTimezone(timezones.find((el) => el.time === user.tz));
        }
    // eslint-disable-next-line
    }, [user.tz])
    
    
    return(
        <div className="timezone">
            <div className="timeezone__wrapper">
                <div className={`timezone__card ${selectedTimezone?.time === currentTimezone?.time && 'selected'}`}>
                    <span>{FormatTimezones(currentTimezone?.time, currentTimezone?.name)}</span>
                    {
                        selectedTimezone?.time === currentTimezone?.time && (
                            <Selected />
                        )
                    }
                </div>
            </div>
            <div className="timeezone__wrapper">
                {
                    otherTimezones.map((el) => (
                            <div
                                key={el?.time}
                                className={`timezone__card ${selectedTimezone?.time === el.time && 'selected'}`}
                                onClick={() => {
                                    setSelectedTimezone(el)
                                }}
                            >
                                <span>{FormatTimezones(el?.time, el?.name)}</span>
                                {
                                    selectedTimezone?.time === el.time && (
                                        <Selected />
                                    )
                                }
                        </div>
                    ))
                }
            </div>
            {
                selectedTimezone?.time !== user.tz && (
                    <MainButton
                        title="Изменить часовой пояс"
                        cancelTouch={false}
                        onClick={() => {
                            console.log('onClick,', selectedTimezone.time, typeof selectedTimezone.time);
                            fetch(`${config.src}/users/tz/`, {
                                method: 'POST',
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    "telegram_id": user.telegram_id,
                                    "tz": selectedTimezone.time,
                                    "auto_tz": false,
                                }),
                            })
                            .then((res) => {
                                return res.json();
                            })
                            .then((re) => {
                                dispatch(setTimezone(selectedTimezone.time))
                                if(!re.message) navigate('/lk')
                            })
                        }}
                    />
                )
            }
            <BottomNotify />
        </div>
    )
}