import React, {useState, useRef, useCallback, useEffect} from "react";
import { nanoid } from "nanoid";

import './index.css'

export default function CalendarList({isMinutes = false, startedHour = 0, onSetTime = () => {}}){

    const ref = useRef(null)
    const count = 10;
    const startedLength = 960;
    const cellHeight = 50;
    const toSecondElement = 25;
    const [timeoutId, setTimeoutId] = useState();

    const [hours, setHours] = useState(
        Array(startedLength)
            .fill("")
            .map((element, index) => {
                const value = isMinutes
                    ? index - 60 * Math.floor(index / 60)
                    : index - 24 * Math.floor(index / 24);
                return ({ value: Math.floor(value / 10) === 0 ? `0${value}` : value, id: nanoid()})
            })
    );

    const marginFromBottom = 50;

    useEffect(() => {
        ref.current.scrollTop = isMinutes
            ? (startedLength - (60 - startedHour)) * cellHeight - toSecondElement
            : (startedLength - (24 - startedHour)) * cellHeight - toSecondElement;
    // eslint-disable-next-line
    }, [])
    
    // eslint-disable-next-line
    const onEndIntersection = () => {
        setHours([
            ...hours,
            ...Array(120)
                .fill('')
                .map((element, index) => {
                    const value = isMinutes
                        ? index - 60 * Math.floor(index / 60)
                        : index - 24 * Math.floor(index / 24);
                    return ({ value: Math.floor(value / 10) === 0 ? `0${value}` : value, id: nanoid()})
                })
        ])
    }

    // eslint-disable-next-line
    const onStartIntersection = () => {
        setHours([
            ...Array(count)
                .fill('')
                .map((element, index) => ({ value: index, id: nanoid()})),
            ...hours,
        ])
        // ref.current.scrollTop = count * 34;
    }

    const toElement = () => {
        const cellIndex = Math.round(ref.current.scrollTop / cellHeight)
        const value = isMinutes
            ? cellIndex - 60 * Math.floor(cellIndex / 60)
            : cellIndex - 24 * Math.floor(cellIndex / 24)
        ref.current.scrollTo({
            top: cellIndex * cellHeight - toSecondElement,
            behavior: 'smooth'
        });
        setTimeout(() => {
            ref.current.scrollTop = isMinutes
                ? (startedLength - (60 - value)) * cellHeight - toSecondElement
                : (startedLength - (24 - value)) * cellHeight - toSecondElement;
        }, [370])
        onSetTime(value)
    }

    const appendArray = useCallback(() => {
        if (ref.current) {
            const containerScrollTop = ref.current.scrollTop;
            const containerHeight = ref.current.clientHeight;
            const scrollHeight = ref.current.scrollHeight;
            if (scrollHeight - containerScrollTop - containerHeight - marginFromBottom <= 0) {
                onEndIntersection();
            }
        }
    // eslint-disable-next-line
    }, [marginFromBottom, ref, onEndIntersection, onStartIntersection])

    return(
        <div className="calendarList___scroll">
            <div
                className="calendarList__container"
                ref={ref}
                onScroll={() => {
                    appendArray()
                    if(timeoutId) {
                        clearTimeout(timeoutId)
                    }
                    setTimeoutId(setTimeout(() => toElement(), 380));
                }}
            >
                {
                    hours.map(element => (
                        <div key={element.id} className="calendarList__element">{element.value}</div>
                    ))
                }
            </div>
        </div>
    )
}