const tasks = [
    {   
        id: 123141231,
        name: 'Название таски 3',
        create_date: new Date(2022, 0, 12),
        author_id: 326249972,
        author_nickname: 'Автор 3',
        description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 1,
        executor_id: 2345563,
        executor_name: 'Исполнитель 3',
        deadline_date: new Date(2022, 11, 31),
        status: 1,
    },
    {
        id: 123141232,
        name: 'Название таски 2',
        create_date: new Date(2022, 0, 12),
        author_id: 1386147264,
        author_nickname: 'Автор 2',
        description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 0,
        executor_id: 2345562,
        executor_name: 'Исполнитель 2',
        deadline_date: new Date(2022, 11, 29),
        status: 0,
    },
    {
        id: 123141233,
        name: 'Название таски 1',
        create_date: new Date(2022, 0, 12),
        author_id: 326249972,
        author_nickname: 'Автор 1',
        description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 0,
        executor_id: 2345561,
        executor_name: 'Исполнитель 1',
        deadline_date: new Date(2022, 11, 30),
        status: 1,
    },
    {   
        id: 123141234,
        name: 'Название таски 4',
        create_date: new Date(2022, 0, 12),
        author_id: 1386147264,
        author_nickname: 'Автор 3',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 1,
        executor_id: 2345563,
        executor_name: 'Исполнитель 3',
        deadline_date: new Date(2022, 11, 31),
        status: 0,
    },
    {
        id: 123141237,
        name: 'Название таски 7',
        create_date: new Date(2022, 0, 12),
        author_id: 326249972,
        author_nickname: 'Автор 2',
        description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 0,
        executor_id: 2345562,
        executor_name: 'Исполнитель 2',
        deadline_date: new Date(2023, 0, 1),
        status: 1,
    },
    {
        id: 123141238,
        name: 'Название таски 8',
        create_date: new Date(2022, 0, 12),
        author_id: 1386147264,
        author_nickname: 'Автор 1',
        description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 1,
        executor_id: 2345561,
        executor_name: 'Исполнитель 1',
        deadline_date: new Date(2023, 0, 1),
        status: 0,
    },
    {
        id: 123141235,
        name: 'Название таски 5',
        create_date: new Date(2022, 0, 12),
        author_id: 326249972,
        author_nickname: 'Автор 2',
        description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 0,
        executor_id: 2345562,
        executor_name: 'Исполнитель 2',
        deadline_date: new Date(2022, 11, 29),
        status: 1,
    },
    {
        id: 123141236,
        name: 'Название таски 6',
        create_date: new Date(2022, 0, 12),
        author_id: 1386147264,
        author_nickname: 'Автор 1',
        description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae soluta accusantium nisi culpa saepe ut aperiam mollitia, id, aliquid rem vero non sed qui magni sit distinctio, natus maxime voluptatibus.',
        priority: 1,
        executor_id: 2345561,
        executor_name: 'Исполнитель 1',
        deadline_date: new Date(2022, 11, 30),
        status: 0,
    },
]

export default tasks;

