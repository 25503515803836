import React from 'react'
import './index.css'

export default function Modal(props) {
    const {
        isOpen = false,
        title = null,
        describtion = null,
        buttons = [],
    } = props;

    return isOpen && (
        <div className="modal__container">
            <div className="modal__wrapper">
                {
                    title && (
                        <div className="modal__title">{title}</div>
                    )
                }
                {
                    describtion && (
                        <div className="modal__describtion">{describtion}</div>
                    )
                }
                {
                    buttons.length > 0 && (
                        <div className="moodal__buttons__container">
                            {
                                buttons.map((button) => (
                                    <div
                                        onClick={button.onClick}
                                        className={`modal__button ${button.type === 'error' && 'modal__button__red'} ${button.type === 'bold' && 'modal__button__bold'}`}
                                        style={{
                                            width: `${100 / buttons.length}%`
                                        }}
                                    >{button.text}</div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}