import React, { useEffect, useRef, useState } from "react"
import { useTelegram } from "../../hooks/useTelegram"
import './index.css'
import { useNavigate } from "react-router-dom";

import Calendar from "../../images/Calendar";
import Critical from "../../images/Critical";
// eslint-disable-next-line
import From from "../../images/From";
// eslint-disable-next-line
import People from "../../images/People"
import config from "../../config";
import Modal from "../../components/modal";
import MainButton from "../../components/button";
import dateForBackend from "../../utils/dateForBackend";
import CustomCalendar from "../../components/calendar";
import { useDispatch, useSelector } from 'react-redux'
import { setIsShowNotify } from "../../slices/appSlice";
import BottomNotify from "../../components/bottomNotify";
import toHumanFullDate from "../../utils/toHumanFullDate";

import { useAmplitude } from "../../hooks/useAmplitude";

import { setCurrentPage } from "../../slices/appSlice";


export default function NewPage(props){
    const dateNow = new Date();

    const startedDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 23, 59)

    const navigate = useNavigate();
    const isFirstRender = useRef(0);
    const state = useSelector((store) => store.appSlice)
    const [taskDescribtion, setTaskDescribtion] = useState('');
    const [sendedTaskDescribtion, setSendedTaskDescribtion] = useState('');
    const [taskProps, setTaskProps] = useState({date: startedDate});
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isFocus, setIsFocus] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModifire, setIsModifire] = useState(false);
    const [isSmallText, setIsSmallText] = useState({transitionPixels: 250, isSmallText: false});


    const [isError, setIsError] = useState(false);

    const trackEvent = useAmplitude();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPage(window.location.href))
    // eslint-disable-next-line
    }, [])


    const inputRef = useRef(null)

    const { tg } = useTelegram();

    const backFunction = (modifire) => {
        if(modifire) {
            trackEvent('exit_without_saving', {
                status: true,
            })
            setIsModalOpen(true);
        } else {
            navigate('/')
        }
    }

    const resetForm = () => {
        setTaskDescribtion('');
        setTaskProps({date: startedDate})
        setIsCalendarOpen(false)
        setIsFocus(false);
        setIsModalOpen(false);
        setIsModifire(false);
    }


    const user = useSelector((store) => store.userSlice);

    useEffect(() => {
        if(isFirstRender.current === 0) {
            isFirstRender.current = isFirstRender.current + 1;
            trackEvent('sample_creation', {status: true});
        } 
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        resetForm();
    // eslint-disable-next-line
    }, [state.notifyParams.isShow])

    useEffect(() => {
        tg.BackButton.show()

        return () => {
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [isModifire])

    useEffect(() => {
        const callback = () => backFunction(isModifire)
        tg.BackButton.onClick(callback)

        return () => {
            tg.BackButton.offClick(callback)
        };
    // eslint-disable-next-line
    }, [isModifire])

    const removeElement = (id) => {
        const newCurrentFilters = Object.assign(taskProps, {})
        delete newCurrentFilters[id];
        setTaskProps({...newCurrentFilters});
    }

    const createTask = () => {
        trackEvent('task_creation', {
            date: taskProps.date === startedDate ? 'default' : 'custom',
            critical: taskProps.critical ? 'custom' : 'default',
            filling_time: new Date() - startedDate,
            button_state: taskDescribtion.length > 0 ? 'enabled' : 'disabled',
            text: taskDescribtion.length,
            time: taskProps.date === startedDate ? 'default' : 'custom',
        });
        if(taskDescribtion) {
            fetch(`${config.src}/tasks/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    author_id: user.telegram_id,
                    description: sendedTaskDescribtion,
                    executor_id: user.telegram_id,
                    priority: taskProps.critical ? 1 : 0,
                    status: 1,
                    ...dateForBackend(taskProps.date, 'deadline_date', user.tz, true),
                })
            })
            .then(async (res) => {
                const json = await res.json()
                console.log('json:', json)
                if(json.message !== "Internal Error") {
                    navigate(`/detail/${json.uid}`)
                    setTimeout(() => {
                        dispatch(setIsShowNotify({
                            type: 'pointup',
                            isShow: true,
                        }))
                    }, 50)
                } else {
                    navigate('/');
                }
            })
            .catch(() => {
                navigate('/');
            })
        } else {
            setIsError(true);
        }
    }


    const filtersList = [
        {
            icon: <Calendar />,
            text: 'дата',
            id: 'date',
        },
        {
            icon: <Critical />,
            text: 'важное',
            id: 'critical',
        },
        // {
        //     icon: <From />,
        //     text: 'от кого',
        //     id: 'from',
        //     secondIcon: <People />
        // },
        // {
        //     icon: <From />,
        //     text: 'кому',
        //     id: 'to',
        //     secondIcon: <People />
        // },
    ]

    return(
        <div className="main_page">
            <div className="header">
                <div style={{ fontFamily: 'Arial, sans-serif' }} className="text header__title">Новая задача</div>
            </div>
            <div className="content">
                <div className={`detail__wrapper ${isError && 'detail__textarea__error'}`}>
                    <div
                        className={`detail__textarea ${isSmallText.isSmallText && 'small__text'}`}
                        contentEditable="true"
                        ref={inputRef}
                        onInput={(e) => {
                            if(!isModifire) {
                                setIsModifire(true);
                            }
                            console.log(e.target.innerText);
                            setTaskDescribtion(e.target.innerText)
                            setSendedTaskDescribtion(e.target.innerHTML)
                            if (e.target.innerHTML !== '') {
                                setIsError(false);
                            }
                            if(inputRef.current.clientHeight > isSmallText.transitionPixels) {
                                setIsSmallText({isSmallText: true, transitionPixels: 200})
                            } else {
                                setIsSmallText({isSmallText: false, transitionPixels: 250})
                            }
                        }}
                        onFocus={(() => {
                            setIsFocus(true);
                        })}
                        onBlur={(() => {
                            setIsFocus(false);
                        })}
                    ></div>
                    {
                        taskDescribtion === '' && !isFocus && (<span className="detail__placeholder">Пиши тут текст задачи, выбери дату дедлайна, исполнителя и приоритеты</span>)
                    }
                    <div className="detail__chip__wrapper">
                        {
                            filtersList.map((element) => {
                                return element.id === 'date' ? (
                                    <label
                                        className={`content__filter detail__filter ${taskProps[element.id] && 'active'}`}
                                        key={element.id}
                                        onClick={() => {
                                            setIsCalendarOpen(true);
                                            // trackEvent('date', {status: true});
                                        }}
                                    >
                                        {
                                            element?.icon && (
                                                <div className="content__filter__icon">{element.icon}</div>
                                            )
                                        }
                                        <div className="content__filter__text">{taskProps[element.id]
                                            ? `${toHumanFullDate(taskProps[element.id])}`
                                            : `${element.text}`}
                                        </div>
                                        {
                                            element?.secondIcon && (
                                                <div className="content__filter__icon">{element.secondIcon}</div>
                                            )
                                        }
                                    </label>
                                ) : (
                                    <div
                                        className={`content__filter detail__filter ${(element.id === 'from' || element.id === 'to') && 'disabled' } ${taskProps[element.id] && 'active'}`}
                                        key={element.id}
                                        onClick={() => {
                                            if(!isModifire) {
                                                setIsModifire(true);
                                            }
                                            if (taskProps[element.id]) {
                                                removeElement(element.id)
                                                if(element.id === 'critical') {
                                                    // trackEvent('critical', {status: 'true'});
                                                }
                                            } else {
                                                setTaskProps({...taskProps, [element.id]: true})
                                                if(element.id === 'critical') {
                                                    // trackEvent('priority', {sample_values: 'custom'});
                                                }
                                            }
                                        }}
                                    >
                                        {
                                            element?.icon && (
                                                <div className="content__filter__icon">{element.icon}</div>
                                            )
                                        }
                                        <div className="content__filter__text">{element.text}</div>
                                        {
                                            element?.secondIcon && (
                                                <div className="content__filter__icon">{element.secondIcon}</div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                title="Выйти без сохранения?"
                describtion="Задача не будет создана"
                buttons={[
                    {
                        text: 'Отмена',
                        onClick: () => { setIsModalOpen(false) },
                        type: 'normal',
                    },
                    {
                        text: 'Удалить',
                        onClick: () => {
                            trackEvent('task_editing', {
                                status: true,
                            })
                            setIsModalOpen(false)
                            navigate('/');
                        },
                        type: 'error',
                    }
                ]}
            />
            <MainButton 
                onClick={createTask}
                disabled={taskDescribtion === '' || !taskProps.date}
                cancelTouch={false}
                title="Создать новую задачу"
            />
            <BottomNotify />
            {
                isCalendarOpen && (
                    <CustomCalendar
                        closeModal={() => {setIsCalendarOpen(false)}}
                        isOpen={isCalendarOpen}
                        isVisibleToday
                        startedTime
                        setDate={(date) => {
                            setTaskProps({...taskProps, date: date})
                        }}
                        isSetStartedValue
                    />
                )
            }
        </div>
    )
}