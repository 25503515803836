import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MainPage from './pages/mainPage2';
import { useSelector } from "react-redux";
import config from './config';
import DetailPage from './pages/detailPageNormal';
import ExpertPage from './pages/detailPageExperts';
import NewPage from './pages/newPage';
import SubscribePage from './pages/subscribePage';
import PolicyPage from './pages/policyPage';
import LkPage from './pages/lkPage';
import NotificationPage from './pages/notificationPage';
import ChangeTimezone from './pages/changeTimezone';

import { useDispatch } from 'react-redux'
import { setUser } from './slices/userSlice';
import { useTelegram } from './hooks/useTelegram';
import { useAmplitude } from './hooks/useAmplitude';
import Marketplace from './pages/marketplace';
import DetailMarketplace from './pages/detailmarketplace';
import { setIsShowNotify } from './slices/appSlice';

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />
  },
  {
    path: "/new",
    element: <NewPage />
  },
  {
    path: "/detail/:detailId",
    element: <DetailPage />
  },
  {
    path: "/expert/:detailId",
    element: <ExpertPage />
  },
  {
    path: "/subscribe",
    element: <SubscribePage />
  },
  {
    path: "/policy",
    element: <PolicyPage />
  },
  {
    path: "/lk",
    element: <LkPage />
  },
  {
    path: '/notify',
    element: <NotificationPage />
  },
  {
    path: "/marketplace",
    element: <Marketplace />
  },
  {
    path: "/expertDetail/:detailId",
    element: <DetailMarketplace />
  },
  {
    path: "/changeTimezone",
    element: <ChangeTimezone />
  },
]);


function App() {

  const dispatch = useDispatch();
  const { tg, user } = useTelegram();

  const isFirstRender = useRef(0);
  const trackEvent = useAmplitude();

  const [fontClass, setFontClass] = useState('');

  const state = useSelector((store) => store.appSlice);
  const ref = useRef();

  let newUser = {};

    if (user && Object.keys(user).length > 0) {
        newUser = {
            ...user,
            phone: user.phone || '',
            telegram_id: user.id
        };
        delete newUser.id;
    } else {
        newUser = {
            telegram_id: 326249972,
            first_name: 'Михаил',
            last_name: 'Михаил',
            username: 'Miroga64',
            phone: '',
        }
    }

  useEffect(() => {
    if(isFirstRender.current === 0) {
        isFirstRender.current = isFirstRender.current + 1;
        trackEvent('start_miniapp', {
            status: true,
        });
    }
    const touchMove = (event) => {
        event = event.originalEvent || event;
        if(event.scale !== 1) {
            event.preventDefault();
        } 
    } 
    document.addEventListener('touchmove', touchMove, false);

    return () => {
        document.removeEventListener('touchmove', touchMove);
    }
  // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if(navigator.appVersion.indexOf('iPhone') !== -1) {
        setFontClass('iphone__fonts')
    } else if (navigator.appVersion.indexOf('Android') !== -1) {
        setFontClass('android__fonts')
    } else {
        setFontClass('laptop__fonts')
    }
  // eslint-disable-next-line
  }, [navigator.appVersion])

  useEffect(() => {
    tg.setHeaderColor(tg.themeParams.bg_color);
    tg.setBackgroundColor(tg.themeParams.secondary_bg_color);
    if(tg && !tg.isExpanded) {
        tg.expand();
    }
    let x = new Date();
    let currentTimeZoneOffsetInHours = -x.getTimezoneOffset() / 60;

    fetch(`${config.src}/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...newUser, tz: currentTimeZoneOffsetInHours})
    })
    .then(async (res) => {
        const {auto_tz, tz} = await res.json();
        dispatch(setUser({
            ...newUser,
            auto_tz,
            tz,
        }));
        if(tz !== currentTimeZoneOffsetInHours && auto_tz) {
            dispatch(setIsShowNotify({
                isShow: true,
                type: 'change_tz',
            }))
        }
      
    })
  // eslint-disable-next-line
  }, [])
  
  return (
    <div
      ref={ref}
      className={`root__container ${fontClass} ${state.isOffScroll && 'blockScroll'} ${tg.colorScheme === 'light' ? 'day' : 'night'}`}>
        <RouterProvider router={router} />
    </div>
  );
}

export default App;