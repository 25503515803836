import './index.css'
import React from 'react'
import BottomNotify from '../../components/bottomNotify'

export default function NotificationPage(){
    return(
        <div className="notifications">
            <div className="notifications__title">Уведомления</div>
            <div className="notifications__wrapper">
                <div className="notifications__element">
                    <div className="notifications__text">Новые задачи от экспертов</div>
                    <label className="notifications__label">
                        <input type="checkbox" className="notifications__input"></input>
                        <div className="notification__switcher"></div>
                    </label>
                </div>
                <div className="notifications__element">
                    <div className="notifications__text">Напоминание о сроке задач в&nbsp;работе</div>
                    <label className="notifications__label">
                        <input type="checkbox" className="notifications__input"></input>
                        <div className="notification__switcher"></div>
                    </label>
                </div>
                <div className="notifications__element">
                    <div className="notifications__text">Напоминание о сроке задач в&nbsp;работе</div>
                    <label className="notifications__label">
                        <input type="checkbox" className="notifications__input"></input>
                        <div className="notification__switcher"></div>
                    </label>
                </div>
                <div className="notifications__element">
                    <div className="notifications__text">Напоминание об оплате подписки</div>
                    <label className="notifications__label">
                        <input type="checkbox" className="notifications__input"></input>
                        <div className="notification__switcher"></div>
                    </label>
                </div>
            </div>
            <BottomNotify />
        </div>
    )
}