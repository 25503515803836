import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setIsShowNotify } from '../../slices/appSlice';
import Lottie from 'lottie-react'
import FormatTimezones from '../../utils/formatTimezones';
import { setIsScrollOff } from '../../slices/appSlice';
import './index.css'
import likeAnimation from '../../images/like.json'
import happyAnimation from '../../images/happy.json'
import Close from '../../images/Close';
import { useAmplitude } from '../../hooks/useAmplitude';
import { setTimezone, setAutoTimezone } from '../../slices/userSlice';
import config from '../../config';


export default function BottomNotify(props){
    const {
        lottie = false,
        buttons: propButtons = false,
        isInput = false,
        title = false,
        onClose = () => {},
        subTitle = false,
        onBlur = () => {},
    } = props;

    const user = useSelector((store) => store.userSlice);
    const state = useSelector((store) => store.appSlice);
    const trackEvent = useAmplitude()
    const navigate = useNavigate();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    let x = new Date();
    let currentTimeZoneOffsetInHours = -x.getTimezoneOffset() / 60;

    useEffect(() => {
        if(ref.current) {
            if(state.notifyParams.isShow) {
                dispatch(setIsScrollOff(true))
            } else {
                dispatch(setIsScrollOff(false))
            }
            if(state.notifyParams.isShow) {
                ref.current.stop()
                setTimeout(() => {
                    ref.current.play()
                }, 450)
            }
            if(isInput) {
                setInputValue('')
            }
        }

        return () => {
            dispatch(setIsScrollOff(false))
        }
    //eslint-disable-next-line
    }, [state.notifyParams.isShow]);

    let buttons = propButtons
        ? [...propButtons]
        : [
            {
                title: 'Создать новую задачу',
                onClick: () => {
                    navigate('/new');
                    dispatch(setIsShowNotify({
                        isShow: false,
                    }))
                },
                type: 'blue'
            },
            {
                title: 'Перейти в список',
                onClick: () => {
                    trackEvent('go_to_list', {
                        status: true,
                    })
                    navigate('/');
                    dispatch(setIsShowNotify({
                        isShow: false,
                    }))
                },
                type: 'basic'
            }
        ]
    buttons = state.notifyParams.type === 'change_tz'
    ? [
        {
            title: 'Обновить часовой пояс',
            onClick: () => {
                fetch(`${config.src}/users/tz/`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        "telegram_id": user.telegram_id,
                        "tz": currentTimeZoneOffsetInHours,
                        "auto_tz": true,
                    }),
                })
                .then(async (res) => {
                    let re = await res.json();
                    if(!re.message) dispatch(setTimezone(currentTimeZoneOffsetInHours))
                    dispatch(setIsShowNotify({isShow: false, type: 'complete'}))
                })
            },
            type: 'blue'
        },
        {
            title: 'Не обновлять',
            onClick: () => {
                fetch(`${config.src}/users/tz/`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        "telegram_id": user.telegram_id,
                        "tz": user.tz,
                        "auto_tz": false,
                    }),
                })
                .then(async (res) => {
                    let re = await res.json();
                    if(!re.message) dispatch(setAutoTimezone(false))
                    dispatch(setIsShowNotify({isShow: false, type: 'complete'}))
                })
            },
            type: 'basic'
        }
    ] : buttons;
    return (
        <div onClick={() => {dispatch(setIsShowNotify({isShow: false}))}} className={`bottom__notify ${state.notifyParams.isShow && 'active'}`}>
            <div className={`bottom__notify__modal ${isInput && 'huge'}`} onClick={(e) => e.stopPropagation()}>
                <div
                    onClick={() => {
                        onClose();
                        dispatch(setIsShowNotify({
                            isShow: false,
                        }))
                    }}
                    className='bottom__notify__close'
                >
                    <Close />
                </div>
                <div className={`bottom__notify__emoji`}>
                    {
                        state.notifyParams.type === 'change_tz' ?
                        (
                            <div style={{fontSize: '130px'}}>🌎</div>
                        )
                        : (
                            <Lottie
                                lottieRef={ref}
                                animationData={lottie ? lottie : state.notifyParams.type === 'complete' ? happyAnimation : likeAnimation}
                                autoPlay={false}
                                loop={false}
                            />
                        )
                    }
                    
                </div>
                <div className='bottom__notify__title'>
                    {
                        state.notifyParams.type === 'change_tz' ?
                        (
                            <span>Обновление настроек</span>
                        )
                        : (
                            title
                                ? title
                                : state.notifyParams.type === 'complete'
                                    ? 'Задача завершена!'
                                    : 'Задача поставлена!'
                        )
                    }
                </div>
                <div className='bottom__notify__text'>
                    {
                        state.notifyParams.type === 'change_tz' ?
                        (
                            <span>Ваш текущий часовой пояс {FormatTimezones(currentTimeZoneOffsetInHours)} и сохраненный в настройках {FormatTimezones(user?.tz)} не совпадают. От часового пояса зависят настройки отображения даты и времени в задачах</span>
                        )
                        : (
                            subTitle
                                ? <span>{subTitle}</span>
                                : state.notifyParams.type === 'complete'
                                    ? <span>Она будет перенесена в архив. Чем&nbsp;займёмся&nbsp;дальше?</span>
                                    : <span>Чем займёмся дальше?</span>
                        )
                    }
                </div>
                {
                    isInput && (
                        <div className="bottom__notify__input__wrapper">
                            <div
                                className="bottom__notify__input"
                                contentEditable
                                onBlur={onBlur}
                                onInput={(e) => {
                                    setInputValue(e.target.textContent)
                                }}
                            />
                            {inputValue === '' && (<span>Напишите здесь все, что думаете</span>)}
                        </div>
                    )
                }
                <div className='bottom__notify__buttons'>
                    {
                        buttons.map((button) => (
                            <div
                                onClick={() => {
                                    if (isInput) {
                                        button.onClick(inputValue)
                                    } else {
                                        button.onClick()
                                    }
                                }}
                                className={`bottom__notify__button ${button.type} ${isInput && inputValue === '' && 'disabled'}`}>
                                <div className='bottom__notify__button__title'>{button.title}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}