import MainButton from "../../components/button";
import Lottie from 'lottie-react'
import lightning from '../../images/lightning2.json'
import Bell from "../../images/Bell";
import Infinite from "../../images/Infinity";
import Okay from "../../images/Okay";
import './index.css'
import { useTelegram } from "../../hooks/useTelegram";
import { useAmplitude } from "../../hooks/useAmplitude"
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import config from "../../config";
import Edit from "../../images/Edit";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowNotify } from "../../slices/appSlice";
import Arrow from "../../images/Arrow";
import BottomNotify from "../../components/bottomNotify";
import Sad from '../../images/Sad.json'


export default function SubscribePage(props){
    const [agree, setAgree] = useState(false);
    const [link, setLink] = useState('')
    const [statusGlobe, setStatusGlobe] = useState()
    const startedDate = useRef(new Date())
    const navigate = useNavigate();

    const trackEvent = useAmplitude();
    const dispatch = useDispatch();

    const { tg } = useTelegram();

    const callback = () => {
        trackEvent('exit_tariff_page', {
            tariff_review_time: new Date() - startedDate.current
        })
        navigate('/lk')
    }

    useEffect(() => {
        tg.BackButton.onClick(callback)
        tg.BackButton.show()

        return () => {
            tg.BackButton.offClick(callback)
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [tg]);

    const user = useSelector((store) => store.userSlice);

    const fetchURL = async () => {
        const statusPromise = await fetch(`${config.src}/subcribe/status/${user.telegram_id}/`)
        const re = await statusPromise.json();
        setStatusGlobe(re);
        if(re.status !== 'active') {
            const url = await fetch(`${config.src}/subcribe/pay/`, {
                method: 'POST',
                headers:{
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    telegram_id: `${user.telegram_id}`, 
                    tariff_id: "1",
                    return_url: "/lk",
                    recurrent: true,
                })
            })
            const {URL} = await url.json();
            setLink(URL);
        }
    }

    useEffect(() => {
        if(user.telegram_id) {
            fetchURL()
        }
    // eslint-disable-next-line
    }, [user])

    return(
        <div className="subscribe">
            <div className="subscribe__bg"></div>
            <div className="subscribe__content">
                <div className="subscribe__block">
                    <div className="subscribe__block__lottie">
                        <Lottie
                            animationData={lightning}
                            loop={false}
                        />
                    </div>
                    <div className="subscribe__block__title">Waflee Premium</div>
                    <div className="subscribe__block__text">
                        {
                            statusGlobe?.status === 'active' && (
                                <>Подписка на задания от авторов экспертного контента! </>
                            )
                        }
                        {
                            (statusGlobe?.status === 'none' || statusGlobe?.status === 'trial') && (
                                <>Оформи подписку на сервис, чтобы продолжить учится новому после окончания бесплатного периода!</>
                            )
                        }
                        {
                            statusGlobe?.status === 'overdue' && (
                                <>Возобнови подписку на сервис, чтобы продолжить учится новому!</>
                            )
                        }
                    </div>
                </div>
                {
                    statusGlobe?.status !== 'active' && (
                        <div className="subscribe__block">
                            <div className="subscribe__block__subtitle">Стоимость подписки</div>
                            <div className="subscribe__block__price"><span>{statusGlobe?.price},00 <span className="rubble__class">₽</span></span> / месяц</div>
                        </div>
                    )
                }
                {
                    statusGlobe?.status === 'active' && (
                        <>
                            <div className="subscribe__block flex">
                                <div className="subscribe__block__describe">Стоимость подписки</div>
                                <div className="subscribe__block__value">{statusGlobe?.price},00 <span className="rubble__class">₽</span> <span className="grey_span">/ месяц</span></div>
                            </div>
                            <div className="subscribe__block flex">
                                <div className="subscribe__block__describe">Дата следующего платежа</div>
                                <div className="subscribe__block__value">{new Date(statusGlobe?.to_date).getDate()}.{new Date(statusGlobe?.to_date).getMonth() + 1 > 10 ? new Date(statusGlobe?.to_date).getMonth() + 1 : `0${new Date(statusGlobe?.to_date).getMonth() + 1}`}.{new Date(statusGlobe?.to_date).getFullYear()}</div>
                            </div>
                        </>
                    )
                }
                <div className="subscribe__subtitle">Преимущества премиума</div>
                <ul className="subscribe__benifits">
                    <li className="subscribe__block subscribe__benifits__element">
                        <div className="subscribe__icon"><Bell /></div>
                        <span>Напоминания о задачах</span>
                    </li>
                    <li className="subscribe__block subscribe__benifits__element">
                        <div className="subscribe__icon"><Infinite /></div>
                        <span>Неограниченный доступ к витрине экспертов и авторским задачам</span>
                    </li>
                    <li className="subscribe__block subscribe__benifits__element">
                        <div className="subscribe__icon"><Okay /></div>
                        <span>Ревью выполненных задач в течение часа</span>
                    </li>
                </ul>
                {
                     (statusGlobe?.status !== 'active') && (
                        <div className="subscribe__agree">
                            <label className="subscribe__agree__label">
                                <input
                                    type="checkbox"
                                    className="subscribe__checkkbox__input"
                                    onClick={() => {
                                        trackEvent('useragreement_checkbox', {
                                            status: agree,
                                        })
                                        setAgree((prev) => !prev)
                                    }}
                                />
                                <div className="subscribe__checkbox">
                                    {
                                        agree && (
                                            <Okay />
                                        )
                                    }
                                </div>
                            </label>
                            <div className="subscribe__agree__text">Я принимаю <span onClick={() =>  {
                                trackEvent('full_useragreement', {
                                    status: true,
                                })
                                navigate('/policy')
                            }}>пользовательское соглашение</span> с Waflee</div>
                        </div>
                     )
                }
                {
                    statusGlobe?.status === 'active' && (
                        <>
                            <div className="subscribe__subtitle">Способ оплаты</div>
                            <ul className="subscribe__benifits">
                                <li
                                    className="subscribe__block subscribe__benifits__element"
                                    onClick={() => {
                                        trackEvent('proceed_to_checkout', {
                                            status: true,
                                        })
                                    }}
                                >
                                    <div className="subscribe__icon"><Edit /></div>
                                    <span>Изменить</span>
                                    <div className="subscribe__benifits__arrow">
                                        <Arrow />
                                    </div>
                                </li>
                            </ul>
                            {
                                statusGlobe?.recurrent && <div className="subscribe__autopay">Автопродление подписки активируется автоматически при первой покупке, чтобы отключить автопродление <span onClick={() => {
                                    trackEvent('subscription_autorenewal', {
                                        status: true,
                                     })
                                    dispatch(setIsShowNotify({
                                        isShow: true
                                    }))
                                }}>нажмите здесь.</span></div>
                            }
                        </>
                    )
                }
            </div>
            {
                (statusGlobe?.status !== 'active') && (
                    <MainButton
                        relative
                        title={`Оплатить ${statusGlobe?.price},00 ₽`}
                        cancelTouch={false}
                        big={true}
                        disabled={!agree}
                        yellow={true}
                        onClick={() => {
                            trackEvent('pay', {
                                payment_review_time: new Date() - startedDate.current,
                                button_state: !agree ? 'disabled' : 'enabled',
                            })
                        }}
                        link={link}
                    />
                )
            }
            <BottomNotify
                isInput={false}
                lottie={Sad}
                title="Отключение автопродления подписки"
                subTitle="В случае, если мы не сможем списать деньги за подписку, она будет отключена и доступ к задачам будет закрыт."
                buttons={[
                    {
                        title: 'Отменить',
                        onClick: (e) => {
                            trackEvent('keep_autorenewal', {
                               status: true,
                            })
                            dispatch(setIsShowNotify({
                                isShow: false
                            }))
                        },
                        type: 'blue',
                    },
                    {
                        title: 'Отключить автопродление',
                        onClick: (e) => {
                            trackEvent('cancel_autorenewal', {
                                status: true,
                            })
                            fetch(`${config.src}/subcribe/cancel_recurent/${user.telegram_id}/`)
                            .then(() => {
                                dispatch(setIsShowNotify({
                                    isShow: false
                                }))
                            })
                        },
                    },
                ]}
            />
        </div>
    )
}