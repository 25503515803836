import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import './index.css'
import Vk from "../../images/vk";
import Tg from "../../images/tg";
import Youtube from "../../images/youtube";
import config from '../../config'
import Task from "../../components/task";
import { useTelegram } from "../../hooks/useTelegram";
import { setCurrentPage, setIsShowNotify } from '../../slices/appSlice';
import BottomNotify from "../../components/bottomNotify";
import ThinkingFace from "../../images/Thinking_Face.json"

export default function DetailMarketplace() {
    const [expert, setExpert] = useState();
    const [socials, setSocials] = useState([]);
    const [isOpenMore, setIsOpenMore] = useState(false);
    const [tasks, setTasks] = useState([]);
    const navigate = useNavigate();
    let location = useLocation();
    const id = location.pathname.split('/')[2];
    const dispatch = useDispatch();

    const { tg } = useTelegram();
    const user =  useSelector((store) => store.userSlice);

    const backFunction = () => {
        navigate('/marketplace');
    }

    const src = config.src.split('/').splice(0, 3).join('/');

    useEffect(() => {
        dispatch(setCurrentPage(window.location.href))
    // eslint-disable-next-line
    }, [])

    const loadExpert= () => {
        fetch(`${config.src}/market/experts/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "expert_id" : id, 
                "user_id" : user.telegram_id,
            }),
        })
            .then(async (res) => {
                let re = await res.json();
                setExpert(re);
            })
    }

    useEffect(() => {
        if(user.telegram_id) {
            tg.BackButton.onClick(backFunction)
            tg.BackButton.show()
            loadExpert()
            fetch(`${config.src}/profile/social_link/${id}/`)
                .then(async (res) => {
                    let re = await res.json();
                    if(Array.isArray(re)) {
                        setSocials(re);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
    
            fetch(`${config.src}/market/last_tasks/${id}/`)
                .then(async (res) => {
                    let re = await res.json();
                    if(Array.isArray(re)) setTasks(re);
                })
        }
        return () => {
            tg.BackButton.offClick(backFunction)
        }
    // eslint-disable-next-line
    }, [user.telegram_id])


    const iconArray = {
        vk: <Vk />,
        telegram: <Tg />,
        youtube: <Youtube />
    }

    return (
        <div
            className="detailmarket"
            onClick={() => {
                console.log('click');
                if(isOpenMore) {
                    setIsOpenMore(false)
                }
            }}
        >
            <div className="detailmarket__expert" style={{backgroundImage: `url(${src}/avatars/${expert?.photo})`}}>
                <div className="detailmarket__name">{expert?.expert_first_name} {expert?.expert_last_name}</div>
                <div className="detailmarket__describe">{expert?.expert_description}</div>
            </div>
            <div className="detailmarket__socials">
                <div
                    onClick={() => {
                        if(expert?.subscribed) {
                            dispatch(setIsShowNotify({
                                isShow: true,
                            }))
                        } else {
                            fetch(`${config.src}/market/follow/`, {
                                method: 'POST',
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    "channel_id" : expert?.channel_id, 
                                    "user_id" : user.telegram_id,
                                }),
                            })
                                .then(async (res) => {
                                    let re = await res.json();
                                    loadExpert();
                                    console.log('re:', re);
                                })
                        }
                    }}
                    className={`detailmarket__subscribe ${expert?.subscribed && 'white'}`}
                >{expert?.subscribed ? 'Вы подписаны': 'Подписаться'}</div>
                {
                    socials?.length > 3 ? (
                        socials?.map((element, index) => index < 2 && <a className="detailmarket__social" href={element.url}>{iconArray[element.link_id]}</a>)
                    ) : (
                        socials?.map((element) => <a className="detailmarket__social" href={element.url}>{iconArray[element.link_id]}</a>)
                    )
                }
                {
                    socials?.length > 3 && (
                        <div className="detailmarket__container">
                            <div
                                className="detailmarket__subscribe more"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsOpenMore(!isOpenMore)
                                }}
                            >...</div>
                            <div className={`detailmarket__more ${isOpenMore && 'opend'}`}>
                                {
                                    socials?.map((element, index) => index > 1 && <a className="detailmarket__social" href={element.url}>{iconArray[element.link_id]}</a>)
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="detail__tasks">
                <div className="marketplace__wrapper__title">Примеры задач<span>{tasks.length}</span></div>
                {
                    (tasks || []).map((taskElement) => (
                        <Task
                            key={taskElement.uid}
                            id={taskElement.id}
                            uid={taskElement.uid}
                            author={taskElement.name}
                            avatar={<div className="detail__task__avatar" style={{backgroundImage: `url(${src}/avatars/${expert?.photo})`}}/>}
                            priority={taskElement.priority}
                            preventDelete
                            text={taskElement.description}
                            data={new Date(taskElement.deadline_date)}
                            onClick={() => {
                                setTimeout(() => {
                                    navigate(`/expert/${taskElement.uid}`)
                                }, 50)
                            }}
                        />
                    ))
                }
            </div>
            <BottomNotify
                lottie={ThinkingFace}
                title={"Управление подпиской"}
                subTitle={"После отмены подписки вы больше не увидите задач от данного эксперта. Вы уверены?"}
                buttons={[
                    {
                        title: 'Отменить подписку',
                        onClick: (e) => {
                            fetch(`${config.src}/market/unfollow/`, {
                                method: 'POST',
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    "channel_id" : expert?.channel_id, 
                                    "user_id" : user.telegram_id,
                                }),
                            })
                            .then(async (res) => {
                                let re = await res.json();
                                loadExpert();
                                dispatch(setIsShowNotify({
                                    isShow: false
                                }))
                                console.log('re:', re);
                            })
                        },
                        type: 'blue',
                    }
                ]}
            />
        </div>
    )
}